import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { DepartmentsProvider } from './DepartmentsContext';


// console.log = () => {};
// console.warn = () => {};
// console.error = () => {};
// console.info = () => {};

ReactDOM.render(
  <DepartmentsProvider>
    <App />
  </DepartmentsProvider>,
  document.getElementById('root')
);
