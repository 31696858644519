import { Button, Popconfirm, Select, Table, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import './VacationReports.css';
import AdminNavbar from './components/AdminNavbar';

const { Option } = Select;

const VacationReports = () => {
	const [vacationData, setVacationData] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [companyId, setCompanyId] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const pageSize = 100;
	const [selectedMonth, setSelectedMonth] = useState(moment().month()); // default to the current month as a numeric index
	const [vacationCount, setVacationCount] = useState({
		alreadyOnVacation: 0,
		willGoOnVacation: 0,
	});

	const fetchCompany = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			if (response.ok) {
				setCompanyId(data.company._id);
			} else {
				console.error('Failed to fetch admin company:', data.error);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const fetchEmployees = async () => {
		if (companyId !== null) {
			const token = localStorage.getItem('token');
			try {
				const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getEmployeesByCompany/${companyId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				const formattedData = await response.json();
				const flattenedData = formattedData.flatMap((employee) => employee.vacations.map(vacation => ({
					status: employee.status,
					employeeName: employee.employeeName,
					eCode: employee.eCode,
					...vacation
				})));
				console.log(flattenedData);
				if (response.ok) {
					setVacationData(flattenedData);
					setDataLoaded(true);
					countVacations(flattenedData);
				} else {
					console.error('Error fetching vacation data:', formattedData.error);
					message.error('Failed to fetch vacation data');
				}
			} catch (error) {
				console.error('Error:', error);
				message.error('Failed to fetch vacation data');
			}
		}
	};

	useEffect(() => {
		fetchCompany();
	}, []);

	useEffect(() => {
		fetchEmployees();
	}, [companyId, selectedMonth]);

	const countVacations = (data) => {
		const firstDayOfSelectedMonth = moment().month(selectedMonth).startOf('month');
		const lastDayOfSelectedMonth = moment().month(selectedMonth).endOf('month');

		let onVacation = 0;
		let willGoOnVacation = 0;
		let filteredData = [];

		data.forEach((employee) => {
			let employeeOnVacation = false;

			if (employee.status === 'Vacation') {
				onVacation += 1;
				employeeOnVacation = true;
			}

			const startDate = moment(employee.startDate);
			const endDate = moment(employee.endDate);

			if (
				(startDate >= firstDayOfSelectedMonth && startDate <= lastDayOfSelectedMonth) ||
				(endDate >= firstDayOfSelectedMonth && endDate <= lastDayOfSelectedMonth) ||
				(startDate <= firstDayOfSelectedMonth && endDate >= lastDayOfSelectedMonth)
			) {
				willGoOnVacation += 1;
				filteredData.push(employee);
			}
		});

		setVacationCount({ alreadyOnVacation: onVacation, willGoOnVacation });
		setVacationData(filteredData);
	};

	const filterEmployees = () => {
		const firstDayOfSelectedMonth = moment().month(selectedMonth).startOf('month');
		const lastDayOfSelectedMonth = moment().month(selectedMonth).endOf('month')

		return vacationData.filter((employee) => {
			// if (employee.status === 'Vacation') {
			// 	return true;
			// }

			const startDate = moment(employee.startDate);
			const endDate = moment(employee.endDate);

			return (
				(startDate >= firstDayOfSelectedMonth && startDate <= lastDayOfSelectedMonth) ||
				(endDate >= firstDayOfSelectedMonth && endDate <= lastDayOfSelectedMonth) ||
				(startDate <= firstDayOfSelectedMonth && endDate >= lastDayOfSelectedMonth)
			);
		});
	};


	const filteredEmployees = filterEmployees();

	const handleMonthChange = (month) => {
		setSelectedMonth(month);
	};

	const columns = [
		{
			title: 'SN',
			dataIndex: 'serial',
			key: 'serial',
			fixed: 'left',
			// width: 50,
			render: (_, __, index) => (currentPage - 1) * pageSize + index + 1
		},
		{
			title: 'E-CODE',
			dataIndex: 'eCode',
			key: 'eCode',
			fixed: 'left',
			// width: 50,
		},
		{
			title: 'EMPLOYEE NAME',
			dataIndex: 'employeeName',
			key: 'employeeName',
			// width: 200,
			// fixed: 'left',
			// render: text => <div >{text}</div>
		},
		{
			title: 'VACATION START DATE',
			dataIndex: 'vacations',
			key: 'startDate',
			width: 200,
			render: (text, employee) => {
				const startDate = employee.startDate;
				return startDate ? moment(startDate).format('YYYY-MM-DD') : 'N/A';
			},
		},
		{
			title: 'VACATION END DATE',
			dataIndex: 'vacations',
			key: 'endDate',
			width: 200,
			render: (text, employee) => {

				const endDate = employee.endDate;
				return endDate ? moment(endDate).format('YYYY-MM-DD') : 'N/A';
			},
		},
		{
			title: 'DAYS',
			dataIndex: 'vacations',
			key: 'days',
			width: 100,
			render: (text, employee) => {
				const startDate = employee.startDate;
				const endDate = moment(employee.endDate);
				const diffDuration = endDate?.diff(startDate, 'days');
				return diffDuration + 1;
			},
		},
		{
			title: 'Action',
			key: 'action',
			width: 100,
			render: (_, employee) => (
				<Popconfirm
					title="Delete the Employee"
					description="Are you sure to delete this vacation?"
					onConfirm={() => handleDeleteVacation(employee)}
					okText="Yes"
					cancelText="No"
				>
					<Button danger icon={<DeleteOutlined />}>

					</Button>
				</Popconfirm>
			),
		}
	];

	const handleDeleteVacation = async (employee) => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/deleteVacation`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ eCode: employee.eCode, vacationId: employee._id }),
			})
			const data = await response.json();
			if (response.ok) {
				message.success('Vacation deleted successfully');
				fetchEmployees();
			} else {
				console.error('Failed to delete vacation:', data);
				message.error('Failed to delete vacation');
			}
		}
		catch (error) {
			console.error('Error:', error);
		}
	};

	const exportToCSV = () => {
		const displayedData = filteredEmployees.map(emp => {
			return {
				serial: emp.serial,
				eCode: emp.eCode,
				employeeName: emp.employeeName,
				status: emp.status,
				startDate: moment(emp.startDate).format('YYYY-MM-DD'),
			};
		});

		const csv = Papa.unparse(displayedData);
		const blob = new Blob([csv], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = 'employees_vacation_data.csv';
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	};

	useEffect(() => {
		window.addEventListener('error', e => {
			if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
				const resizeObserverErrDiv = document.getElementById(
					'webpack-dev-server-client-overlay-div'
				);
				const resizeObserverErr = document.getElementById(
					'webpack-dev-server-client-overlay'
				);
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute('style', 'display: none');
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute('style', 'display: none');
				}
			}
		});
	}, []);

	console.log(filteredEmployees)

	return (
		<>
			<AdminNavbar />
			<div className="">
				<div className="dashboard mt-3">
					{/* <h3 style={{ color: '#11686D' }}>Employee Vacation Reports</h3> */}
					<div className="row">
						<div className="col-sm-6">
							<div className="grid-cell">
								<div className="cell-label">Already On Vacation</div>
								<div className="cell-content">{vacationCount.alreadyOnVacation}</div>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="grid-cell">
								<div className="cell-label">Will Go On Vacation</div>
								<div className="cell-content">{vacationCount.willGoOnVacation}</div>
							</div>
						</div>
					</div>
					<div style={{ padding: '1vh 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Select
							style={{ width: '20%', fontFamily: 'var(--font)' }}
							value={selectedMonth}
							onChange={(value) => handleMonthChange(value)}
						>
							{moment.months().map((month, index) => (
								<Option key={index} value={index}>
									{month}
								</Option>
							))}
						</Select>
						<Button
							style={{ backgroundColor: "#6FB555", margin: "1vh 1vw", padding: '0 1vw', height: '23px' }}
							type="primary"
							onClick={exportToCSV}
						>
							Export to CSV
						</Button>
					</div>
					<div style={{ width: '100%', marginTop: '1rem' }}>
						<Table
							pagination={{ pageSize: 100, current: currentPage, onChange: (page) => setCurrentPage(page) }}
							dataSource={filteredEmployees}
							loading={!dataLoaded}
							columns={columns}
							scroll={{
								x: 'max-content',
								y: `calc(70vh - 250px)`
							}}
							size='middle'
						/>
					</div>
				</div>
				{/* <div className="home-logo">
					<Link to="/admin" style={{ color: '#11686D', paddingRight: '3vw' }}>
						<HomeFilled />
					</Link>
				</div> */}
			</div>
		</>
	);
};

export default VacationReports;
