import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Table, Button, Modal, Form, Input, Dropdown, Radio, InputNumber, Space, Tooltip, DatePicker, message, Select, Col, Row, Popover, Popconfirm, Spin } from 'antd';
import moment from 'moment';
import { DownloadOutlined, PlusCircleOutlined, ExclamationCircleOutlined, UsergroupAddOutlined, FileAddOutlined, MoreOutlined, DeleteOutlined, SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import Navbar from './components/Navbar';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import './AssetManagement.css'
import { employeeAttributes, vehicleAttributes, vehicleImportAttributes } from './components/Data';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import { debounce } from 'lodash'; // lodash debounce function
const { Option } = Select;
const VehiclesManagement = () => {

    const [isAssignEmpModalVisible, setIsAssignEmpModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [form] = Form.useForm();
    const [updateModalVisible, setUpdateModalVisible] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [companies, setCompanies] = useState([]);
    const [unassignedEmployees, setUnassignedEmployees] = useState([]);
    const [exportConfirmed, setExportConfirmed] = useState(false);
    const [exportData, setExportData] = useState([]);
    const [companyMap, setCompanyMap] = useState({});
    const csvLinkRef = useRef();
    const assignSampleEl = useRef();
    const sampleEl = useRef();
    const fileInputRef = useRef();
    const fileInputRefAssign = useRef();
    const [employees, setEmployees] = useState([]);
    const [assignModal, setAssignModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(-1);
    const [saveLoading, setSaveLoading] = useState(false);
    const [vehicleIndex, setVehicleIndex] = useState(-1);
    const pageSize = 100;
    const { RangePicker } = DatePicker;
    const [dateRange, setDateRange] = useState([]);
    const [isTimelineModalVisible, setIsTimelineModalVisible] = useState(false);
    const [filteredTimelineData, setFilteredTimelineData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const statusLabels = {
        'active': 'Active',
        'inactive': 'Inactive',
        'cancelled': 'Cancelled',
        'maintenance': 'Under Maintenance',
    }
    const statusColors = {
        'active': '#10ca10',
        'inactive': 'orange',
        'cancelled': 'red',
        'maintenance': 'orange',
    }

    // const url = ${process.env.REACT_APP_API_URI};
    const url = 'https://hrms-5u7j.onrender.com';
    // const url = 'https://hrms-5u7j.onrender.com';
    useEffect(() => {
        fetchVehicles();
        fetchCompany();
        fetchEmployees();
    }, []);


    const token = localStorage.getItem('token');

    const handleOk = () => {
        setExportConfirmed(true);

    };

    useEffect(() => {
        if (exportConfirmed && csvLinkRef.current) {
            csvLinkRef.current.link.click();
            setExportConfirmed(false);
        }
    }, [exportConfirmed]);

    const fetchCompany = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/manager/companies', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setCompanies(data.companies);

                const companyMap = data.companies.reduce((map, company) => {
                    map[company.companyName] = company._id;
                    return map;
                }, {});
                setCompanyMap(companyMap);
            } else {
                message.error(data.error || 'Failed to fetch company');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch company');
        }
    };

    const fetchEmployees = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(url + '/manager/unassigned-employees-vehicles', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                setEmployees(data);
            } else {
                message.error(data.error || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch employees');
        }
    };

    const fetchVehicles = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(url + '/manager/fetch-all-vehicles', {
                headers: {
                    Authorization: `Bearer ${token} `,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                setVehicles(data);
                setFilteredData(data);
                setExportData(data.map((vehicle, index) => {
                    return {
                        ...vehicle,
                        eCode: vehicle.assignedEmployee?.eCode,
                        assignedEmployee: vehicle.assignedEmployee?.employeeName,
                        employeeStatus: vehicle.assignedEmployee?.status,
                        company: vehicle.company?.companyName
                    }
                }))
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            message.error('Failed to fetch vehicles: ' + error.message);
        }
        setIsLoading(false);
    };
    const debouncedFilter = useCallback(
        debounce((query) => {
            const lowercasedFilter = query.toLowerCase();
            const filtered = vehicles.filter((vehicle) => {
                return (
                    vehicle.plateNumber?.toLowerCase().includes(lowercasedFilter) ||
                    vehicle.assignedEmployee?.eCode?.toLowerCase().includes(lowercasedFilter) ||
                    vehicle.vehicleStatus?.toLowerCase().includes(lowercasedFilter) ||
                    (vehicle.assignedEmployee && vehicle.assignedEmployee.employeeName.toLowerCase().includes(lowercasedFilter))
                );
            });
            setFilteredData(filtered);
        }, 300),
        [vehicles]
    );

    // Update search query and filter the data
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchText(query);
        debouncedFilter(query); // Trigger the debounced filter
    };
    const showModal = () => {
        form.resetFields();
        setIsModalVisible(true);
    };

    const showAssignModal = (vehicle, index) => {
        setVehicleIndex(index);
        setPopoverOpen(-1);
        setSelectedVehicle(JSON.parse(JSON.stringify(vehicle)));
        setAssignModal(true);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setUpdateModalVisible(false);
        form.resetFields();
    };

    const handleAddOrUpdate = async (values, id, print = true) => {
        if (saveLoading)
            return;
        setSaveLoading(true);
        try {
            const new_url = id ? url + `/manager/update-vehicle/${id}` : url + '/manager/create-vehicle';
            const method = id ? 'PUT' : 'POST';
            const response = await fetch(new_url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(values)
            });
            const data = await response.json();
            if (response.ok) {
                if (print)
                    message.success(`Vehicle ${id ? 'updated' : 'added'} successfully`);
                fetchVehicles();
                handleCancel();
            }
            else {
                message.error(id ? 'Vehicle not found' : 'Vehicle already exists');
            }
        } catch (error) {
            message.error(id ? 'Failed to update vehicle' : 'Failed to add vehicle');
        }
        setSaveLoading(false);
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(url + `/manager/delete-vehicle/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                message.success('Vehicle deleted successfully');
                fetchVehicles();
            } else {
                message.error('Failed to delete vehicle');
            }
        } catch (error) {
            message.error('Failed to delete vehicle');
        }
    };

    const downloadSample = () => {
        if (sampleEl.current)
            sampleEl.current.link.click();
    }

    const handleAssignCancel = () => {
        setAssignModal(false);
        setSelectedVehicle(null);
    }

    const handleAssignSubmit = async (vehicle) => {
        if (saveLoading)
            return;
        setSaveLoading(true);
        try {
            const response = await fetch(url + `/manager/assign-vehicle`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(vehicle)
            })
            const data = await response.json();
            console.log(data)
            if (response.ok) {
                if (vehicle.assignedEmployee._id == -1)
                    message.success('Vehicle unassigned successfully');
                else
                    message.success('Vehicle assigned successfully');
                setFilteredData(data)
                setVehicles(data)
                fetchEmployees();
                setAssignModal(false);
            }
            else {
                console.error(data)
                message.error('Failed to assign vehicle');
            }
        }
        catch (error) {
            console.error('Error:', error);
            message.error('Failed to assign vehicle: ' + error.message);
        }
        setSaveLoading(false);
    }

    const handleAssignCSV = (file) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
                // Parse the CSV and prepare data to send
                const vehicles = results.data.map((vehicle) => ({
                    plateNumber: vehicle['Plate Number'],
                    ecode: vehicle['Employee Code'],
                    vehicleStatus: vehicle['Status'],
                    remarks: vehicle['Remarks'],
                    date: vehicle['Date'],
                }));

                try {
                    // Send all vehicles in a single API call
                    const response = await fetch(url + `/manager/assign-vehicles-csv`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({ vehicles }), // Send the array of vehicles
                    });

                    const data = await response.json();
                    if (response.ok) {
                        for (const error of data.errors) {
                            message.error(error);
                        }
                        message.success('All vehicles assigned successfully');
                        setFilteredData(data.updatedVehicles); // Update vehicle list
                        fetchEmployees(); // Fetch employees if needed
                    } else {
                        console.error(data);
                        message.error(`Failed to assign vehicles: ${data.message}`);
                    }
                } catch (error) {
                    console.error('Error:', error);
                    message.error('Failed to assign vehicles: ' + error.message);
                }
            },
            error: (error) => {
                console.error('Error parsing CSV file:', error);
                alert('Error parsing CSV file');
            },
        });
    };

    const columns = [
        // {
        //     title: 'SN',
        //     dataIndex: 'serial',
        //     key: 'serial',
        //     width: 100,
        //     fixed: 'left',
        //     render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
        //     sorter: (a, b) => a.serial - b.serial
        // },
        {
            title: 'Plate Number', dataIndex: 'plateNumber', key: 'plateNumber', width: 250, fixed: 'left', sorter: (a, b) => a.plateNumber.localeCompare(b.plateNumber),
            render: (_, record, index) => (
                <div className='d-flex justify-content-between'>
                    <Link to={`/manager/vehicles/${record._id}`}>{record.plateNumber}</Link>
                    <Popover
                        onOpenChange={(e) => setPopoverOpen(e ? index : -1)}
                        open={popoverOpen == index}
                        placement='right'
                        trigger='click'
                        content={
                            <div className='employee-shortcuts pe-5'>
                                <Link onClick={() => showAssignModal(record, index)}>Assign Employee</Link>
                                <Link
                                    onClick={() => {
                                        form.setFieldsValue({ ...record, istemaraExpiry: moment(record.istemaraExpiry), companyName: record.company.companyName });
                                        setSelectedVehicle(record);
                                        setPopoverOpen(-1);
                                        setUpdateModalVisible(true);
                                    }}
                                    style={{ color: 'orange' }}
                                >
                                    Update Vehicle Status
                                </Link>
                                <Popconfirm
                                    title='Delete Vehicle'
                                    description='Are you sure you want to delete this vehicle?'
                                    onConfirm={() => handleDelete(record._id)}
                                    okText='Yes'
                                >

                                    <Link style={{ color: 'red' }}>Delete Vehicle</Link>
                                </Popconfirm>
                            </div>
                        }
                    >
                        <MoreOutlined />
                    </Popover>
                </div>
            )
        },
        {
            title: 'Vehicle Status', width: 250, dataIndex: 'vehicleStatus',
            render: (_, record) => <p style={{ color: statusColors[record.vehicleStatus], margin: 0 }}>{statusLabels[record.vehicleStatus]}</p>,
            key: 'vehicleStatus', sorter: (a, b) => a.vehicleStatus?.localeCompare(b.vehicleStatus)
        },
        { title: 'E Code', dataIndex: 'eCode', width: 100, key: 'eCode', render: (_, record) => record.assignedEmployee?.eCode, sorter: (a, b) => (a.assignedEmployee?.eCode || '').localeCompare(b.assignedEmployee?.eCode || ''), },
        { title: 'Reference Number', dataIndex: 'refNo', width: 200, key: 'refNo', render: (_, record) => record.assignedEmployee?.refNo, sorter: (a, b) => (a.assignedEmployee?.refNo || '').localeCompare(b.assignedEmployee?.refNo || ''), },
        {
            title: 'Assigned Employee', width: 350, dataIndex: 'assignedEmployee', key: 'assignedEmployee', render: (_, record) => record.assignedEmployee?.employeeName, sorter: (a, b) => (a.assignedEmployee?.employeeName || '').localeCompare(b.assignedEmployee?.employeeName || ''),
        }, ,
        {
            title: 'Employee status', width: 200, dataIndex: 'status', key: 'status',
            render: (_, record) => <p style={{ color: record.assignedEmployee?.status === 'Active' ? '#10ca10' : record.assignedEmployee?.status === 'Terminated/Resigned' ? 'red' : 'orange', margin: 0 }}>{record.assignedEmployee?.status}</p>,
            sorter: (a, b) => (a.assignedEmployee?.status || '').localeCompare(b.assignedEmployee?.status || ''),
        },
        { title: 'Company', dataIndex: 'company', key: 'company', width: 150, ellipsis: true, render: (_, record) => record.company.companyName, sorter: (a, b) => (a.company?.companyName || '').localeCompare(b.company?.companyName || ''), },
        { title: 'Istemara Expiry', width: 200, dataIndex: 'istemaraExpiry', key: 'istemaraExpiry', ellipsis: true, render: (_, record) => record.istemaraExpiry ? moment(record.istemaraExpiry).format('DD-MM-YYYY') : '', sorter: (a, b) => moment(a.istemaraExpiry).unix() - moment(b.istemaraExpiry).unix(), },
    ];

    const findVehicle = (id) => {
        let vehicle = employees.find(e => e._id === id)
        if (id == vehicles[vehicleIndex].assignedEmployee?._id)
            vehicle = vehicles[vehicleIndex].assignedEmployee
        setSelectedVehicle({ ...selectedVehicle, assignedEmployee: vehicle ? vehicle : { _id: -1 } })
    }

    const handleImport = async (file) => {
        // Parse the CSV file
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
                const vehicles = results.data.map(vehicle => {
                    const companyId = companyMap[vehicle.company];
                    if (!companyId) {
                        message.warning(`Company "${vehicle.company}" not found. Skipping vehicle.`);
                        return null;
                    }

                    return {
                        plateNumber: vehicle.plateNumber,
                        vehicleStatus: vehicle.vehicleStatus,
                        vehicleType: vehicle.vehicleType,
                        istemaraExpiry: new Date(vehicle.istemaraExpiry),
                        chassisNumber: vehicle.chassisNumber,
                        make: vehicle.make,
                        model: vehicle.model,
                        company: companyId,
                    };
                }).filter(Boolean); // Filter out any null vehicles

                if (vehicles.length === 0) {
                    message.warning('No valid vehicles to import.');
                    return;
                }

                // Send all vehicles in one API request
                try {
                    const response = await fetch(url + `/manager/import-vehicles`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,  // Add your token here if required
                        },
                        body: JSON.stringify({ vehicles }),
                    });

                    const data = await response.json();

                    if (response.ok) {
                        message.success('Vehicles imported successfully!');
                    } else {
                        console.error(data);
                        message.error(`Failed to import vehicles: ${data.message}`);
                    }
                } catch (error) {
                    console.error('Error importing vehicles:', error);
                    message.error('Failed to import vehicles.');
                }
            },
            error: (error) => {
                console.error('Error parsing CSV file:', error);
                message.error('Error parsing CSV file.');
            }
        });
    };


    const handleImportClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleDateRangeChange = (dates) => {
        if (dates && dates.length === 2) {
            const formattedDates = [moment(dates[0]).startOf('day'), moment(dates[1]).endOf('day')];
            setDateRange(formattedDates);
            console.log("Date range set to:", formattedDates.map(date => date.format('YYYY-MM-DD')));
        } else {
            setDateRange([]);
            console.log("Date range cleared");
        }
    };

    const handleExportTimeline = () => {
        if (!dateRange || dateRange.length !== 2) {
            message.error("Please select both start and end dates.");
            return;
        }

        const start = dateRange[0].startOf('day').format('YYYY-MM-DD'); // Change to your actual start date
        const end = dateRange[1].endOf('day').format('YYYY-MM-DD');    // Change to your actual end date
        console.log('date range:', dateRange)

        const filteredData = vehicles.reduce((acc, vehicle) => {
            console.log('Processing vehicle with plate number:', vehicle.plateNumber);

            const timelineEvents = (vehicle.timeline || [])
                .filter(event => {
                    const eventDate = moment(event.date); // Convert event date to UTC
                    const isWithinRange = eventDate.isBetween(start, end, null, '[]'); // Include events on the start and end dates

                    console.log('eventDate:', eventDate.format('YYYY-MM-DD HH:mm:ss'), 'isWithinRange:', isWithinRange);
                    return isWithinRange;
                })
                .map(event => ({
                    // date: moment.utc(event.date).format('YYYY-MM-DD'), // Keep date formatting in UTC
                    description: event.description,
                    vehiclePlate: vehicle.plateNumber,
                    eCode: vehicle.assignedEmployee?.eCode,
                    referenceCode: vehicle.assignedEmployee?.refNo,
                    assignedEmployee: vehicle.assignedEmployee?.employeeName || '',
                    actionDate: moment(event.date).format('YYYY-MM-DD'), // Full date and time
                    status: vehicle.vehicleStatus || '',

                }));

            // Log filtered timeline events for this vehicle
            console.log('Filtered Events for vehicle:', vehicle.plateNumber, timelineEvents);

            // Only add to the accumulator if there are filtered events
            if (timelineEvents.length > 0) {
                acc = acc.concat(timelineEvents);
            }

            return acc;
        }, []);

        // Log the final filtered data
        console.log('Final Filtered Data:', filteredData);




        if (filteredData.length === 0) {
            message.error("No events found in the selected date range.");
            return;
        }

        setFilteredTimelineData(filteredData);
        setTimeout(() => {
            document.getElementById('export-timeline-csv').click();
        }, 0);
    };

    const handleFileInputClick = () => {
        fileInputRefAssign.current.click();
    };


    return (
        <div>
            <Navbar />
            <div className='dashboard mt-3'>
                <div className="d-flex justify-content-between my-2">
                    <Input.Search
                        placeholder='Search vehicles'
                        onChange={handleSearchChange}
                        allowClear
                        style={{ width: '500px' }}
                    />
                    <div className='d-flex gap-3'>
                        <Tooltip title='Export Timeline'>
                            <Button type='text' shape='circle' icon={<CalendarOutlined style={{ fontSize: 20 }} onClick={() => setIsTimelineModalVisible(true)} />} />
                        </Tooltip>
                        <Modal
                            title="Select Date Range for Timeline"
                            visible={isTimelineModalVisible}
                            onOk={handleExportTimeline}
                            onCancel={() => setIsTimelineModalVisible(false)}
                            okText="Export"
                        >
                            <RangePicker
                                onChange={(dates) => setDateRange(dates)}
                                format="YYYY-MM-DD"
                            />
                        </Modal>

                        <CSVLink
                            id="export-timeline-csv"
                            data={filteredTimelineData}
                            headers={[
                                { label: "Description", key: "description" },
                                { label: "Vehicle Plate", key: "vehiclePlate" },
                                { label: "E Code", key: "eCode" },
                                { label: "Reference Code", key: "referenceCode" },
                                { label: "Assigned Employee", key: "assignedEmployee" },
                                { label: "Action Date", key: "actionDate" },
                                { label: "Status", key: "status" },
                            ]}
                            filename={`vehicles-timeline-${startDate?.format('YYYYMMDD')}-${endDate?.format('YYYYMMDD')}.csv`}
                            style={{ display: 'none' }}
                        />
                        <Popconfirm
                            title="Download Sample File"
                            description="Do you wish to download the sample file?"
                            onConfirm={downloadSample}
                            onCancel={handleImportClick}
                            cancelText="Import"
                            okText="Download"
                        >
                            <Tooltip title='Import Vehicles'>
                                <Button type='text' shape='circle' icon={<FileAddOutlined style={{ fontSize: 20 }} />} />
                            </Tooltip>
                        </Popconfirm>
                        <Popconfirm
                            title="Download Sample File"
                            description="Do you wish to download the sample file?"
                            onConfirm={() => assignSampleEl.current?.link.click()}
                            onCancel={handleFileInputClick}
                            cancelText="Import"
                            okText="Download"
                        >
                            <Tooltip title='Import Employees'>
                                <Button type='text' shape='circle' icon={<UsergroupAddOutlined style={{ fontSize: 20 }} />} />
                            </Tooltip>
                        </Popconfirm>

                        <input type="file" ref={fileInputRefAssign} style={{ display: 'none' }} accept=".csv" onChange={(e) => {
                            handleAssignCSV(e.target.files[0]);
                            e.target.value = null;
                        }} />
                        <Tooltip title='Export'>
                            <Button type='text' shape='circle' icon={<DownloadOutlined style={{ fontSize: 20 }} />} onClick={handleOk} />
                        </Tooltip>
                        <Tooltip title='Add Vehicle'>
                            <Button type='text' shape='circle' icon={<PlusCircleOutlined style={{ fontSize: 20 }} />} onClick={showModal} />
                        </Tooltip>
                    </div>
                </div>
                <CSVLink
                    style={{ display: 'none' }}
                    id='export-vehicle-csv'
                    data={exportData}
                    headers={vehicleAttributes.map((attr) => ({ label: attr, key: attr }))}
                    filename={'vehicles.csv'}
                    ref={csvLinkRef}
                >
                    Download Vehicle CSV
                </CSVLink>

                <CSVLink
                    style={{ display: 'none' }}
                    data={[]}
                    headers={vehicleImportAttributes.map((attr) => ({ label: attr, key: attr }))}
                    filename="create-vehicles-sample.csv"
                    ref={sampleEl}
                />

                <CSVLink
                    style={{ display: 'none' }}
                    data={[]}
                    headers={[
                        { label: "Plate Number", key: "Plate Number" },
                        { label: "Employee Code", key: "Employee Code" },
                        { label: "Status", key: "Status" },
                        { label: "Remarks", key: "Remarks" },
                        { label: "Date", key: "Date" }
                    ]}
                    filename="assign-vehicles-sample.csv"
                    ref={assignSampleEl}
                />

                <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".csv" onChange={(e) => { handleImport(e.target.files[0]); e.target.value = null; }} />


                {/* <div className='d-flex justify-content-between my-2'>
                    <Button type="primary" onClick={showModal}>Add Vehicle</Button>
                </div> */}
                <div className={'d-flex justify-content-between gap-1'}>
                    <div className='table' >
                        <Table
                            loading={isLoading}
                            pagination={{
                                pageSize: 100,
                                current: currentPage,
                                onChange: (page) => setCurrentPage(page)
                            }}
                            dataSource={filteredData}
                            // loading={dataLoaded ? false : !dataLoaded}
                            columns={columns}
                            rowKey="_id"
                            scroll={{
                                x: 'max-content',
                                y: 'calc(90vh - 250px)'
                            }}
                        // rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                        />
                        {/* <Table columns={columns} dataSource={vehicles} rowKey="_id" /> */}
                    </div>
                    <Modal className='create-vehicle' okText={saveLoading ? <Spin size='small' /> : 'Create'} title="Add New Vehicle" open={isModalVisible} onOk={() => form.submit()} onCancel={handleCancel}>
                        <Form form={form} layout="vertical" onFinish={values => handleAddOrUpdate(values)}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="plateNumber" label="Plate Number" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="vehicleStatus" label="Vehicle Status" rules={[{ required: true }]}>
                                        <Select placeholder="Select a vehicle status">
                                            <Option value="active">Active</Option>
                                            <Option value="inactive">Inactive</Option>
                                            <Option value="maintenance">Under Maintenance</Option>
                                            <Option value="cancelled">Cancelled</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="vehicleType" label="Vehicle Type" rules={[{ required: true }]}>
                                        <Select placeholder="Select a vehicle type">
                                            <Option value="Car">Car</Option>
                                            <Option value="Bike">Bike</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="istemaraExpiry" label="Istemara Expiry" rules={[{ required: true }]}>
                                        <DatePicker className='w-100' format="YYYY-MM-DD" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="chassisNumber" label="Chassis Number">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="make" label="Make" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="model" label="Model" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="company" label="Company" rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            placeholder="Select a company"
                                            optionFilterProp="label"
                                            options={companies.map((company, index) => {
                                                return { label: company.companyName, value: company._id }
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    <Modal title="Update Vehicle Status" okText={saveLoading ? <Spin size='small' /> : 'Save'} open={updateModalVisible} onOk={() => form.submit()} onCancel={handleCancel}>
                        <Form form={form} layout="vertical" onFinish={values => handleAddOrUpdate(values, selectedVehicle._id)}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="plateNumber" label="Plate Number">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12} />
                                <Col span={12}>
                                    <Form.Item name="vehicleStatus" label="Vehicle Status" rules={[{ required: true }]}>
                                        <Select placeholder="Select a vehicle status">
                                            <Option value="active">Active</Option>
                                            <Option value="inactive">Inactive</Option>
                                            <Option value="maintenance">Under Maintenance</Option>
                                            <Option value="cancelled">Cancelled</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name="statusDate" label="Status Change Date">
                                        <DatePicker className='w-100' format="YYYY-MM-DD" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="remarks" label="Remarks">
                                        <Input />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Form>
                    </Modal>
                    <Modal title='Assign Employee' okText={saveLoading ? <Spin size='small' /> : 'Save'} open={assignModal} onOk={() => handleAssignSubmit(selectedVehicle)} onCancel={handleAssignCancel}>
                        {assignModal &&
                            <div className='d-flex gap-2 mt-3'>

                                <Select
                                    showSearch
                                    placeholder="Assign Employee"
                                    optionFilterProp="label"
                                    onChange={(e) => findVehicle(e)}
                                    value={selectedVehicle.assignedEmployee ? selectedVehicle.assignedEmployee._id : -1}
                                    className='w-75'
                                    options={[
                                        { label: 'None', value: -1 },
                                        ...(vehicleIndex >= 0 && vehicles[vehicleIndex].assignedEmployee
                                            ? [{ label: `${vehicles[vehicleIndex].assignedEmployee.eCode}: ${vehicles[vehicleIndex].assignedEmployee.employeeName}`, value: vehicles[vehicleIndex].assignedEmployee._id }]
                                            : []),
                                        ...employees.map((e, index) => {
                                            return { label: `${e.eCode}: ${e.employeeName}`, value: e._id }
                                        })
                                    ]}
                                />
                                <DatePicker format="YYYY-MM-DD" value={selectedVehicle.assignedDate ? selectedVehicle.assignedDate : dayjs()} onChange={(e) => setSelectedVehicle({ ...selectedVehicle, assignedDate: e })} />
                            </div>
                        }
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default VehiclesManagement;