import React, { useEffect, useState } from 'react';
import './SickLeave.css';
import { DatePicker, FloatButton, Input, Tooltip, message } from 'antd';
import html2canvas from 'html2canvas';
import { Link, useParams } from 'react-router-dom';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';
import Navbar from '../components/Navbar';
import dayjs from 'dayjs';
const { TextArea } = Input;

const EmploymentTermination = () => {
    const [logoComponent, setLogoComponent] = useState(null);
    const { ecode } = useParams();
    const [formData, setFormData] = useState({
        employeeName: '',
        eCode: ecode,
        position: '',
        refNo: '',
        firstName: ''
    });
    const text = 'With reference to the outcome of Probation Period Performance Evaluation we regret to advise that your employment is now terminated with immediate effect as your performance has not met the standard required.'


    // useEffect(() => {
    // 	fetchAndDisplayLogo().then((logo) => {
    // 		setLogoComponent(logo);
    // 	});
    // }, []);

    const handleDownload = async () => {
        const pdfElement = document.querySelector('section-to-print');

        if (!pdfElement) {
            console.error('PDF element not found.');
            return;
        }

        const options = {
            margin: 0,
            filename: 'employee-termination.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(pdfElement).set(options).save();
    };

    useEffect(() => {
        if (ecode) {
            fetchEmployeeInfo(ecode)
        }
    }, []);

    const fetchEmployeeInfo = async (employeeCode) => {
        // console.log(employeeCode)
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getByECode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify({ eCodes: employeeCode }),
            });

            if (!response.ok) {
                message.error('Failed to fetch employee information');
                return;
                // throw new Error('Failed to fetch employee information');
            }

            const employeeInfo = await response.json(); // This line extracts the body of the response
            if (employeeInfo.length > 0)
                setFormData({ ...employeeInfo[0], firstName: employeeInfo[0].employeeName.split(' ')[0] });
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        // console.log(formData);
    }, [formData]);

    return (
        <>
            <div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
                <Navbar />
            </div>
            <div className="center-container" style={{ paddingTop: 120 }}>
                {/* <p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p> */}
                <div className="disciplinary-action-form">
                    <div className='local-leave-container'>
                        <div className='form-container termination-letter' id='section-to-print' style={{ border: '1px solid black', padding: '20px', height: '355mm', width: '250mm' }} >
                            <div className='row'>
                                <div className='col-md-2'>{logoComponent}</div>
                                <div className='col-md-8'>
                                    <h2 style={{ fontWeight: 'bolder', border: 'none' }}><br />Employment Termination Letter</h2>
                                </div>
                                <div className='col-md-2' >
                                </div>
                            </div><br />
                            <div className="row form-fields">
                                <div className="col-md-12 ">
                                    <label>Ref.</label>
                                    <TextArea autoSize value={formData.refNo} name='refNo' onChange={handleChange} style={{ width: '40%', textAlign: 'center' }} type="text" />
                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '15px' }}>
                                    <label>Date:</label>
                                    <DatePicker defaultValue={dayjs()} style={{ width: '40%' }} />
                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '50px' }}>
                                    <label>Name:</label>
                                    <TextArea autoSize style={{ borderRadius: 0, width: '40%' }} onChange={handleChange} name="employeeName" value={formData.employeeName} type="text" />
                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '15px' }}>
                                    <label>Employee Code:</label>
                                    <Input onKeyDown={(e) => e.key === "Enter" && fetchEmployeeInfo(formData.eCode)} onChange={handleChange} name="eCode" value={formData.eCode} style={{ borderRadius: 0, width: '40%', textAlign: 'center' }} type="text" />
                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '15px' }}>
                                    <label>Designation:</label>
                                    <TextArea autoSize style={{ borderRadius: 0, width: '40%' }} onChange={handleChange} name="position" value={formData.position} type="text" />
                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '15px' }}>
                                    <label>QID Number:</label>
                                    <TextArea value={formData.qidNumber} name='qidNumber' onChange={handleChange} autoSize style={{ borderRadius: 0, width: '40%' }} type="text" />
                                </div>
                            </div>
                            <div className="row form-fields" style={{ paddingTop: '80px' }}>
                                <div className="col-md-12  ">
                                    <label style={{ fontWeight: '500' }}>Dear </label>
                                    <TextArea value={formData.firstName} name='firstName' onChange={handleChange} autoSize style={{ borderRadius: 0, width: '40%' }} type="text" />
                                    <label style={{ fontWeight: '500' }}>,</label>

                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '30px' }}>
                                    <TextArea autoSize style={{ fontWeight: '600', width: '100%', border: 'none', textAlign: 'left', fontSize: 20 }} defaultValue={text}></TextArea>
                                </div>
                            </div>
                            <hr className='signature-hr' style={{ marginTop: '60px' }}></hr>
                            <h5 style={{ fontWeight: 'bolder' }}>Manager/Director</h5>
                            <div style={{ marginTop: '100px' }}>
                                <label style={{ fontWeight: '600' }}>Distribution:    Personnel File</label>
                            </div>
                            <div>
                                <label style={{ float: 'right', fontWeight: '600' }}>Rev. 00</label>
                            </div>

                        </div>
                    </div>
                    {/* <Tooltip title="Print" placement='right'>
			<FloatButton
				icon={<PrinterOutlined />}
				onClick={() => window.print()}
				style={{
					right: 100,
				}}
			/>
			</Tooltip> */}
                    <Tooltip title="Download" placement='left'>
                        <FloatButton
                            icon={<DownloadOutlined />}
                            onClick={handleDownload}
                            style={{
                                right: 150,
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
        </>
    );
};

export default EmploymentTermination;
