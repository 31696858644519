import { useEffect, useState } from "react";
import { Table, message } from "antd";
import { Link } from "react-router-dom";

const ManagerAdminList = () => {
    const [users, setUsers] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);  // Maintain the current page in the state
    const pageSize = 100;


    const fetchForms = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/manager/getAdmins', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                setUsers(data);
                setDataLoaded(true);
            } else {
                message.error(data.error || 'Failed to fetch admins');
                setDataLoaded(true);
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch admins');
            setDataLoaded(true);
        }
    };

    useEffect(() => {
        fetchForms();
    }, []);

    const columns = [
        {
            title: 'SN',
            dataIndex: 'serial',
            key: 'serial',
            // fixed: 'left',
            render: (_, __, index) => (currentPage - 1) * pageSize + index + 1
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            sorter: (a, b) => {
                if (a.company === null || a.company === undefined) return 1;
                if (b.company === null || b.company === undefined) return -1;
                return a.company.localeCompare(b.company);
            },
        },
        {
            title: 'User Type',
            dataIndex: 'userType',
            key: 'userType',
            sorter: (a, b) => a.userType.localeCompare(b.userType),
        },
        {
            title: 'Access Type',
            dataIndex: 'accessType',
            key: 'accessType',
            render: (access) => (access ? 'Full' : 'Limited'),
            sorter: (a, b) => (a.accessType ? 1 : 0) - (b.accessType ? 1 : 0),
        }
    ];

    return (
        <div className="flex-fill pt-5">
            <div style={{ width: '90%' }}>
                <Table
                    pagination={{
                        pageSize: 100,
                        current: currentPage,
                        onChange: (page) => setCurrentPage(page)
                    }}
                    dataSource={users}
                    loading={dataLoaded ? false : !dataLoaded}
                    columns={columns}
                    rowKey="_id"
                    scroll={{
                        x: 'max-content',
                        y: `calc(90vh - 250px)`
                    }}
                />

            </div>
        </div>
    );
}

export default ManagerAdminList;