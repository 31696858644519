import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import './LeaveApplication.css';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';

const TrainingRequirement = () => {
    const [logoComponent, setLogoComponent] = useState(null);

	// useEffect(() => {
	// 	fetchAndDisplayLogo().then((logo) => {
	// 		setLogoComponent(logo);
	// 	});
	// }, []);

    const handleDownload = async () => {
        const pdfElement = document.querySelector('.leave-application-form');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'training-requirement.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
    };

    return (
        <div className="center-container">
            <p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p>
            <div className="leave-application-form" style={{ backgroundColor: 'white', fontWeight: 'bolder', minHeight: '297mm', width: '210mm' }}>
                {logoComponent}
                <h4 style={{ textAlign: 'center' }}>Training Approval Request Form with Employee Undertaking for refund</h4>
                <br />
                <table className="leave-table training-requirement">
                    <tr>
                        <td>
                            <label>Name of Employee</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                        <td>
                            <label>Position</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Location / Project</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                        <td>
                            <label>Employee No.</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                </table>
                <br></br>
                <table className="leave-table training-requirement" >
                    <tr>
                        <td>
                            <label>Name of Training Programme / Qualification</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Method of Study (e.g. Distance Learning / Certification courses/Within Qatar/Outside Qatar etc)</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Study Time Required (if any)</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Training Provider</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Start Date and Date of Completion</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Duration of Training</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Total Training Cost</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                </table>
                <table className="leave-table training-requirement" >
                    <tr>
                        <td style={{ backgroundColor: '#7da9cf', textAlign: 'center' }}>
                            <label>PART ONE – To be completed by Requestor and discussed with Line Manager</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Skill / Carrier Development (How will this training benefit the individual, line manager and the organization?):</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type="text" style={{ height: '80px' }} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>What are you expecting to learn on this course (your objectives)?</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type="text" style={{ height: '80px' }} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>What preparation will you do before attending the course?</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type="text" style={{ height: '80px' }} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>What skills / knowledge will be developed as a result of attending this training?</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type="text" style={{ height: '80px' }} />
                        </td>
                    </tr>
                    <h4 style={{ fontSize: 'small' }}>SD-HR-F08</h4><br/><br/><br/><br/><br/><br/>
                    <tr>
                        <td>
                            <label>What will help you to apply the knowledge you gain to your area of work?</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type="text" style={{ height: '80px' }} />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bolder' }}>
                            <label>Employee Undertaking for Refund of Training Costs:</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>I <input type="text" style={{ width: '100px', borderBottom: '1px solid' }} /> employee No <input type="text" style={{ width: '50px', borderBottom: '1px solid' }} /> hereby declare my willingness to refund to
                                the company the training fees incurred on me if I resign from the company at any time within two years from date of
                                attending this training as per the Training Policy of the company..</label>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ backgroundColor: '#7da9cf', height: '30px' }}>
                        </td>
                    </tr>
                </table>
                <table className="leave-table">
                    <td>
                        <label>Signed (employee):</label>
                    </td>
                    <td>
                        <input type="text" />
                    </td>
                    <td>
                        <label>Date:</label>
                    </td>
                    <td>
                        <input type="text" />
                    </td>
                </table>
                <table className="leave-table">
                    <tr>
                        <td style={{ backgroundColor: '#7da9cf', textAlign: 'center' }}>
                            <label>PART TWO – To be completed by Line Manager</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Supporting Comments:</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type="text" style={{ height: '80px' }} />
                        </td>
                    </tr>
                </table>
                <table className="leave-table">
                    <td>
                        <label>Signed</label>
                    </td>
                    <td>
                        <input type="text" />
                    </td>
                    <td>
                        <label>Date:</label>
                    </td>
                    <td>
                        <input type="text" />
                    </td>
                </table>
                <table className="leave-table">
                    <tr>
                        <td style={{ backgroundColor: '#7da9cf', textAlign: 'center' }}>
                            <label>PART THREE – To be completed by Operations Manager/Country Manager</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Supporting Comments:</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type="text" style={{ height: '80px' }} />
                        </td>
                    </tr>
                </table>
                <table className="leave-table">
                    <tr>
                        <td>
                            <label>Signed</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                        <td>
                            <label>Date:</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                </table>
                <table className="leave-table">
                    <tr>
                        <td style={{ backgroundColor: '#7da9cf', textAlign: 'center' }}>
                            <label>PART FOUR – to be completed by HR Department / Higher Management</label>
                        </td>
                    </tr>
                </table>
                <table className="leave-table">
                    <tr>
                        <td>
                            <label>Authorised By:</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                        <td>
                            <label>Date:</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Regional
                                Managing Director
                                *</label>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                        <td>
                        </td>
                        <td>
                            <input type="text" />
                        </td>
                    </tr>
                </table>
                <table className="leave-table">
                    <tr>
                        <td>
                            <label>Notes: * For any outside Qatar training.</label>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingBottom: '80px' }}>
                            <label>Additional Comments from HRM:</label>
                        </td>
                    </tr>
                </table>
                <h4 style={{ fontSize: 'small' }}>SD-HR-F08</h4>
            </div>
            <div className="button-group">
                <button className="download-button" onClick={handleDownload}>
                    Download
                </button>
            </div>
        </div>
    );
};

export default TrainingRequirement;