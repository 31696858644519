const IsoCodes = {
    "afghanistan": "AF",
    "albania": "AL",
    "algeria": "DZ",
    "andorra": "AD",
    "angola": "AO",
    "antigua and barbuda": "AG",
    "argentina": "AR",
    "armenia": "AM",
    "australia": "AU",
    "austria": "AT",
    "azerbaijan": "AZ",
    "bahamas": "BS",
    "bahrain": "BH",
    "bangladesh": "BD",
    "barbados": "BB",
    "belarus": "BY",
    "belgium": "BE",
    "belize": "BZ",
    "benin": "BJ",
    "bhutan": "BT",
    "bolivia": "BO",
    "bosnia and herzegovina": "BA",
    "botswana": "BW",
    "brazil": "BR",
    "brunei darussalam": "BN",
    "bulgaria": "BG",
    "burkina faso": "BF",
    "burundi": "BI",
    "cabo verde": "CV",
    "cambodia": "KH",
    "cameroon": "CM",
    "canada": "CA",
    "central african republic": "CF",
    "chad": "TD",
    "chile": "CL",
    "china": "CN",
    "colombia": "CO",
    "comoros": "KM",
    "congo (congo-brazzaville)": "CG",
    "congo, democratic republic of the": "CD",
    "costa rica": "CR",
    "croatia": "HR",
    "cuba": "CU",
    "cyprus": "CY",
    "czech republic": "CZ",
    "denmark": "DK",
    "djibouti": "DJ",
    "dominica": "DM",
    "dominican republic": "DO",
    "ecuador": "EC",
    "egypt": "EG",
    "el salvador": "SV",
    "equatorial guinea": "GQ",
    "eritrea": "ER",
    "estonia": "EE",
    "eswatini": "SZ",
    "ethiopia": "ET",
    "fiji": "FJ",
    "finland": "FI",
    "france": "FR",
    "gabon": "GA",
    "gambia": "GM",
    "georgia": "GE",
    "germany": "DE",
    "ghana": "GH",
    "greece": "GR",
    "grenada": "GD",
    "guatemala": "GT",
    "guinea": "GN",
    "guinea-bissau": "GW",
    "guyana": "GY",
    "haiti": "HT",
    "honduras": "HN",
    "hungary": "HU",
    "iceland": "IS",
    "india": "IN",
    "indian": "IN",
    "indonesia": "ID",
    "iran": "IR",
    "iraq": "IQ",
    "ireland": "IE",
    "israel": "IL",
    "italy": "IT",
    "jamaica": "JM",
    "japan": "JP",
    "jordan": "JO",
    "kazakhstan": "KZ",
    "kenya": "KE",
    "kenyan": "KE",
    "kiribati": "KI",
    "korea (democratic people's republic of)": "KP",
    "korea": "KR",
    "kuwait": "KW",
    "kyrgyzstan": "KG",
    "lao people's democratic republic": "LA",
    "latvia": "LV",
    "lebanon": "LB",
    "lesotho": "LS",
    "liberia": "LR",
    "libya": "LY",
    "liechtenstein": "LI",
    "lithuania": "LT",
    "luxembourg": "LU",
    "madagascar": "MG",
    "malawi": "MW",
    "malaysia": "MY",
    "maldives": "MV",
    "mali": "ML",
    "malta": "MT",
    "marshall islands": "MH",
    "mauritania": "MR",
    "mauritius": "MU",
    "mexico": "MX",
    "micronesia": "FM",
    "moldova": "MD",
    "monaco": "MC",
    "mongolia": "MN",
    "montenegro": "ME",
    "morocco": "MA",
    "mozambique": "MZ",
    "myanmar": "MM",
    "namibia": "NA",
    "nauru": "NR",
    "nepal": "NP",
    "nepalese": "NP",
    "netherlands": "NL",
    "new zealand": "NZ",
    "nicaragua": "NI",
    "niger": "NE",
    "nigeria": "NG",
    "north macedonia": "MK",
    "norway": "NO",
    "oman": "OM",
    "pakistan": "PK",
    "palau": "PW",
    "palestine, state of": "PS",
    "panama": "PA",
    "papua new guinea": "PG",
    "paraguay": "PY",
    "peru": "PE",
    "philippines": "PH",
    "poland": "PL",
    "portugal": "PT",
    "qatar": "QA",
    "romania": "RO",
    "russian federation": "RU",
    "rwanda": "RW",
    "saint kitts and nevis": "KN",
    "saint lucia": "LC",
    "saint vincent and the gratadines": "VC",
    "samoa": "WS",
    "san marino": "SM",
    "sao tome and principe": "ST",
    "saudi arabia": "SA",
    "senegal": "SN",
    "serbia": "RS",
    "seychelles": "SC",
    "sierra leone": "SL",
    "sieeraa leone": "SL",
    "singapore": "SG",
    "slovakia": "SK",
    "slovenia": "SI",
    "solomon islands": "SB",
    "somalia": "SO",
    "south africa": "ZA",
    "south sudan": "SS",
    "spain": "ES",
    "sri lanka": "LK",
    "srilanka": "LK",
    "sudan": "SD",
    "suriname": "SR",
    "sweden": "SE",
    "switzerland": "CH",
    "syrian arab republic": "SY",
    "tajikistan": "TJ",
    "tanzania": "TZ",
    "thailand": "TH",
    "timor-leste": "TL",
    "togo": "TG",
    "tonga": "TO",
    "trinidad and tobago": "TT",
    "tunisia": "TN",
    "turkey": "TR",
    "turkmenistan": "TM",
    "tuvalu": "TV",
    "uganda": "UG",
    "ukraine": "UA",
    "united arab emirates": "AE",
    "united kingdom of great britain and northern ireland": "GB",
    "united states of america": "US",
    "uruguay": "UY",
    "uzbekistan": "UZ",
    "vanuatu": "VU",
    "venezuela": "VE",
    "vietnam": "VN",
    "yemen": "YE",
}


const employeeAttributes = [
    'eCode',
    'employeeName',
    'refNo',
    'status',
    'companyName',
    'position', // this is an additional attribute present in your schema but not in your given list
    'qidNumber',
    'qidExpiryDate',
    'hiringDate',
    'startingDate', // seems to map to 'talabatStartingDate' in your given list
    'companyNumber', // was mapped from 'companyPhoneNumber' in your schema
    'personalNumber',
    'nationality',
    'passportNumber',
    'passportExpiryDate',
    'rejoiningDate',
    'healthCard',
    'healthCardExpiryDate', // was mapped from 'healthCardExpiry' in your given list
    'bankAccountIBAN',
    'birthDate', // seems to map to 'dateOfBirth' in your given list
    'healthCertificateExpiryDate', // was mapped from 'healthCertificateExpiry' in your given list
    'terminationDate',
    'driverLicenseType',
    'driverLicenseExpiryDate', // was mapped from 'driverLicenseExpiry' in your given list
    'visaNumber',
    'visaExpiryDate',
    'contract',
    'lifeInsurance',
    'basicSalary', // seems to map to 'totalSalary' in your given list
    'loanAmount',
    'recruitmentSource',
    'religion',
    'maritalStatus',
    'refName',
    'accomodation',
    'note',

    // 'workTenure',
];

const vehicleAttributes = [
    'plateNumber',
    'vehicleStatus',
    'vehicleType',
    'eCode',
    'assignedEmployee',
    'employeeStatus',
    'istemaraExpiry',
    'chassisNumber',
    'make',
    'model',
    'company'
];

const vehicleImportAttributes = [
    'plateNumber',
    'vehicleType',
    'istemaraExpiry',
    'chassisNumber',
    'make',
    'model',
    'company'
];

const accomodationExportAttributes = [
    'propertyName' ,
    'rooms' ,
    'waterMeterNumber' ,
    'electricityMeterNumber' ,
    'totalCapacity' ,
    'occupancy' ,
	'vacancies',
];

const employeeAttributeLabels = {
    eCode: "Employee Code",
    employeeName: "Employee Name",
    refNo: "Reference Number",
    companyName: "Company Name",
    status: "Status",
    position: "Position",
    qidNumber: "QID Number",
    qidExpiryDate: "QID Expiry Date",
    hiringDate: "Hiring Date",
    startingDate: "Starting Date",
    rejoiningDate: "Re-Joining Date",
    companyNumber: "Company Number",
    personalNumber: "Personal Number",
    nationality: "Nationality",
    passportNumber: "Passport Number",
    passportExpiryDate: "Passport Expiry Date",
    healthCard: "Health Card",
    healthCardExpiryDate: "Health Card Expiry Date",
    bankAccountIBAN: "Bank Account IBAN",
    birthDate: "Birth Date",
    // workTenure: "Work Tenure",
    healthCertificateExpiryDate: "Health Certificate Expiry Date",
    terminationDate: "Termination Date",
    driverLicenseType: "Driver License Type",
    driverLicenseExpiryDate: "Driver License Expiry Date",
    visaNumber: "Visa Number",
    visaExpiryDate: "Visa Expiry Date",
    contract: "Contract",
    lifeInsurance: "Life Insurance",
    basicSalary: "Basic Salary",
    loanAmount: "Loan Amount",
    recruitmentSource: "Recruitment Source",
    religion: "Religion",
    maritalStatus: "Marital Status",
    refName: "Reference Name",
    accomodation: "Accomodation",
};

const employeeAttributeDataTypes = {
    eCode: 1,
    employeeName: 2,
    refNo: 1,
    refName: 2,
    companyName: 2,
    status: 2,
    position: 2,
    qidNumber: 1,
    qidExpiryDate: 3,
    hiringDate: 3,
    startingDate: 3,
    companyNumber: 1,
    personalNumber: 1,
    nationality: 2,
    passportNumber: 2,
    passportExpiryDate: 3,
    healthCard: 2,
    healthCardExpiryDate: 3,
    bankAccountIBAN: 2,
    birthDate: 3,
    healthCertificateExpiryDate: 3,
    rejoiningDate: 3,
    terminationDate: 3,
    driverLicenseType: 2,
    driverLicenseExpiryDate: 2,
    visaNumber: 1,
    visaExpiryDate: 3,
    contract: 2,
    lifeInsurance: 2,
    basicSalary: 1,
    loanAmount: 4,
    recruitmentSource: 2,
    religion: 2,
    maritalStatus: 2,
    accomodation: 2,
};

// SL NO:	Talabat ID	Emp Code.	Talabat Name	RIDERS NAME.	QID	IBAN	Status	Pickups 	Pickup  Income	Dropoffs	Dropoffs Rate	Dropoffs Income	Distance Pay	Total Talabat Income	FA	SICK LEAVE	OT/Addition	Other	TOTAL BENEFITS	Late Login 	No Show	Penalties	COD	Company Deduction	TOTAL DEDUCTION	TOTAL PAYABLES

const payrollLabels = {
    'slNo': 'Sl No',
    'talabatId': 'Talabat ID',
    'eCode': 'Emp Code',
    'talabatName': 'Talabat Name',
    'ridersName': 'Riders Name',
    'qid': 'QID',
    'iban': 'IBAN',
    'status': 'Status',
    'position': 'Position',
    'contract': 'Contract',
    'pickups': 'Pickups',
    'pickupIncome': 'Pickup Income',
    'dropoffs': 'Dropoffs',
    'dropoffsRate': 'Dropoffs Rate',
    'dropoffsIncome': 'Dropoffs Income',
    'distancePay': 'Distance Pay',
    'totalTalabatIncome': 'Total Talabat Income',
    'fa': 'FA',
    'sickLeave': 'Sick Leave',
    'otAddition': 'OT/Addition',
    'other': 'Other',
    'totalBenefits': 'Total Benefits',
    'carRental': 'Car Rental',
    'accommodation': 'Accommodation',
    'mobile': 'Mobile',
    'settlement': 'Settlement',
    'lateLogin': 'Late Login',
    'noShow': 'No Show',
    'penalties': 'Penalties',
    'cod': 'COD',
    'companyDeduction': 'Company Deduction',
    'totalDeduction': 'Total Deduction',
    'totalPayables': 'Total Payables',
}

export {
    IsoCodes,
    employeeAttributes,
    employeeAttributeLabels,
    employeeAttributeDataTypes,
    vehicleAttributes,
    vehicleImportAttributes,
    accomodationExportAttributes,
    payrollLabels
};
