import { EditOutlined, PoweroffOutlined, SettingOutlined, UploadOutlined, UserOutlined, CheckOutlined, BellOutlined, FileSearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Form, Input, Modal, Popover, Tooltip, message, Button, Divider, notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchAndDisplayLogo } from '../HRforms/LogoFetcher';

const Settings = ({ setSettingsOpen }) => {
    const [adminCompany, setAdminCompany] = useState('');
    const [logoComponent, setLogoComponent] = useState(null);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchAndDisplayLogo().then((logo) => {
            setLogoComponent(logo);
        });
    }, []);

    const fetchAdminCompany = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                const companyName = data.company.companyName;
                setAdminCompany(companyName);
                // scn(companyName);
                // fetchDepartments(companyName)
                // console.log("companyName", companyName);
                return companyName; // Return the company name directly
            } else {
                console.error('Failed to fetch admin company:', data.error);
                return Promise.reject(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            // throw error;
        }
    };

    const handleUploadLogo = async (e) => {
        const logoFile = e.target.files[0];
        // console.log(logoFile);
        try {
            const token = localStorage.getItem('token');

            let currentCompanyId = companyId;

            if (!currentCompanyId) {
                const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompanyAtt', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                // console.log('data', data, data.company);
                currentCompanyId = data.company?._id;


                if (currentCompanyId) {
                    setCompanyId(currentCompanyId);
                } else {
                    message.error('Failed to fetch company ID');
                    return;
                }
            }

            const formData = new FormData();
            formData.append('logo', logoFile);

            const response = await fetch(`https://hrms-5u7j.onrender.com/admin/${currentCompanyId}/addCompanyLogo`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
            // console.log('response', response);

            if (response.ok) {
                fetchAndDisplayLogo().then((logo) => {
                    setLogoComponent(logo);
                });
                message.success('Logo uploaded successfully');
            } else {
                message.error('Failed to upload logo');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to upload logo');
        }
    };
    const handleLogout = () => {
        localStorage.clear(); // Clears all localStorage data
        navigate('/adminLogin'); // Redirects to the home or login page
    };
    const handleUpdatePassword = async () => {
        if (!currentPassword || !newPassword || !confirmNewPassword) {
            message.error('Please fill in all password fields');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            message.error('New passwords do not match');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const adminEmail = localStorage.getItem('adminEmail');
            const requestData = { adminEmail, currentPassword, newPassword };

            // Log the data being sent
            // console.log('Data being sent:', requestData);

            const response = await fetch('https://hrms-5u7j.onrender.com/admin/update-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                message.success('Password updated successfully');
                handleLogout();
            } else {
                const data = await response.json();
                message.error(`Failed to update password: ${data.message}`);
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to update password');
        }
    };

    useEffect(() => {
        fetchAdminCompany();
    }, []);

    const handlePasswordModalClick = () => {
        setSettingsOpen(false);
        setShowPasswordModal(true);
    };

    return (
        <div className='settings-container'>
            <h5>{adminCompany}</h5>
            {logoComponent ?
                <div className='settings-icon'>{logoComponent}</div>
                :
                <div className='settings-icon'><UserOutlined /></div>
            }
            <div className='buttons-container'>
                <div onClick={() => document.getElementById('upload-logo').click()}>
                    <UploadOutlined />
                    Upload Logo
                </div>
                <div onClick={handlePasswordModalClick}>
                    Update Password
                    <EditOutlined />
                </div>
            </div>
            <input id='upload-logo' type="file" onChange={handleUploadLogo} style={{ display: 'none' }} />
            <Modal
                // title="Update Password"
                open={showPasswordModal}
                onOk={handleUpdatePassword}
                onCancel={() => setShowPasswordModal(false)}
            >
                <Form layout="vertical">
                    <Form.Item label="Current Password">
                        <Input.Password value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="New Password">
                        <Input.Password value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Confirm New Password">
                        <Input.Password value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

const AdminNavbar = ({ toggleVisible }) => {
    const navigate = useNavigate();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [notifications, setNotifications] = useState({
        today: [],
        yesterday: [],
        older: [],
    });
    const location = useLocation();
    const items = [
        {
            key: '1',
            label: (
                <Link className='dropdown-link' to='/admin/analysis/reporting'>
                    Status Analysis
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link className='dropdown-link' to='/admin/analysis/date-reports'>
                    Date Reports
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                <Link className='dropdown-link' to='/admin/analysis/vacation-reports'>
                    Vacation Reports
                </Link>
            ),
        },
        {
            key: '4',
            label: (
                <Link className='dropdown-link' to='/admin/analysis/sick-leave-reports'>
                    Sick Leave Reports
                </Link>
            ),
        },
    ]

    const earnings = [
        {
            key: '1',
            label: (
                <Link className='dropdown-link' to='/admin/earnings/salaries'>
                    Salaries and Payroll
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link className='dropdown-link' to='/admin/earnings/payroll-setup'>
                    Payroll Setup
                </Link>
            ),
        },
    ]

    const handleLogout = () => {
        localStorage.removeItem('managerName');
        localStorage.removeItem('managerEmail');
        localStorage.removeItem('token');
        navigate('/');
    };

    const isActive = (path) => {
        return location.pathname.startsWith('/admin/' + path) ? 'active' : '';
    };

    const groupNotificationsByDate = (notifications) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        const grouped = {
            today: [],
            yesterday: [],
            older: [],
        };

        notifications.forEach((notification) => {
            const notificationDate = new Date(notification.date); // Assuming each notification has a `date` field
            if (
                notificationDate.getDate() === today.getDate() &&
                notificationDate.getMonth() === today.getMonth() &&
                notificationDate.getFullYear() === today.getFullYear()
            ) {
                grouped.today.push(notification);
            } else if (
                notificationDate.getDate() === yesterday.getDate() &&
                notificationDate.getMonth() === yesterday.getMonth() &&
                notificationDate.getFullYear() === yesterday.getFullYear()
            ) {
                grouped.yesterday.push(notification);
            } else {
                grouped.older.push(notification);
            }
        });

        return grouped;
    };

    const fetchNotifications = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://hrms-5u7j.onrender.com/admin/notifications', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setNotificationCount(data.length);
                setNotifications(groupNotificationsByDate(data));
            }
            else {
                console.error('Error:', data.message);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const readNotification = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://hrms-5u7j.onrender.com/admin/read-notification', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ id }),
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data)
                fetchNotifications();
            }
            else {
                console.error('Error:', data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const readAllNotifications = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://hrms-5u7j.onrender.com/admin/read-all-notifications', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            });
            const data = await response.json();
            if (response.ok) {
                setNotifications({
                    today: [],
                    yesterday: [],
                    older: [],
                });
                setNotificationCount(0);
                message.success('All notifications marked as read');
            }
            else {
                console.error('Error:', data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const leftEyeRef = useRef(null);
    const rightEyeRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            const moveEye = (eye) => {
                const eyeRect = eye.getBoundingClientRect();
                const eyeX = eyeRect.left + eyeRect.width / 2;
                const eyeY = eyeRect.top + eyeRect.height / 2;
                const rad = Math.atan2(event.clientX - eyeX, event.clientY - eyeY);
                const rot = (rad * (180 / Math.PI) * -1) + 230;
                eye.style.transform = `rotate(${rot}deg)`;
            };

            if (leftEyeRef.current) moveEye(leftEyeRef.current);
            if (rightEyeRef.current) moveEye(rightEyeRef.current);
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const logoIcons = [
        <span className='logo-container' style={{ backgroundColor: 'limegreen' }}>
            <CheckOutlined style={{ fontSize: '15px' }} />
        </span>,
        <span className='logo-container'>
            <FileSearchOutlined style={{ fontSize: '15px' }} />
        </span>,
        <span className='logo-container' style={{ backgroundColor: 'sandybrown' }}>
            <FileExcelOutlined style={{ fontSize: '15px' }} />
        </span>,
    ]

    const getNotificationIcon = (message) => {
        if (message.includes('Termination'))
            return logoIcons[1];
        if (message.includes('Leave'))
            return logoIcons[2];
        return logoIcons[0];
    };

    useEffect(() => {
        console.log('notifications:', notifications);
    }, [notifications]);


    return (
        <header className='header'>
            <Link className='ps-5 position-relative' to='/admin'>
                <img src='/crootive.png' draggable='false' alt='logo' />
                <div className='eye-container'>
                    <div className='eye' ref={leftEyeRef}></div>
                    <div className='eye' ref={rightEyeRef}></div>
                </div>
            </Link>

            <div className='nav-links align-items-center'>
                <Link to='/admin/employeeDataDashboard' className={isActive('employeeDataDashboard')}>Dashboard</Link>
                <Dropdown className='dropdown' menu={{
                    items
                }}>
                    <Link className={isActive('analysis')}>Analysis & Reporting</Link>
                </Dropdown>
                <Link to='/admin/savedFiles' className={isActive('savedFiles')}>Saved Files</Link>
                <Link to='/admin/hr-forms' className={isActive('hr-forms')}>HR Forms</Link>
                <Dropdown menu={{ items: earnings }}>
                    <Link className={isActive('earnings')}>Earnings</Link>
                </Dropdown>
                <Link to='/admin/approvals' className={isActive('approvals')}>Approvals</Link>
                <Link to='/admin/looker-studio'><img src='/looker-studio.svg' style={{ height: '30px' }} draggable='false' alt='logo' /></Link>
                <Link to='/adminLogin' className='logout' onClick={handleLogout}><PoweroffOutlined style={{ fontSize: 23 }} /></Link>
                <Popover onOpenChange={setSettingsOpen} open={settingsOpen} placement='bottomRight' trigger={'click'} content={<Settings setSettingsOpen={setSettingsOpen} />}>
                    <Link><SettingOutlined style={{ fontSize: 25 }} /></Link>
                </Popover>
                <Popover trigger={'click'} placement='bottomRight' content={
                    <div className={'noti-container'}>
                        {notificationCount > 0 ?
                            <div className='d-flex justify-content-end'>
                                <Link onClick={() => readAllNotifications()}>Mark all as read</Link>
                            </div>
                            :
                            <div className='no-notifications'>
                                <img src="/bell-slash.png" />
                                <h5>No notifications yet</h5>
                                <p style={{ textAlign: 'center' }}>When there are notifications, they will appear here</p>
                                <Button type='text' onClick={() => fetchNotifications()}>Refresh</Button>
                            </div>
                        }
                        {notifications.today.length > 0 &&
                            <>
                                <Divider orientation='left'> Today </Divider>
                                {notifications.today.map((notification, index) => (
                                    <Tooltip title='Mark as read'>
                                        <div className='notification'>
                                            {getNotificationIcon(notification.message)}
                                            <p onClick={() => readNotification(notification._id)} key={index}>
                                                {notification.message}
                                            </p>
                                        </div>
                                    </Tooltip>
                                ))}
                                <div style={{ height: '10px' }}></div>
                            </>
                        }

                        {notifications.yesterday.length > 0 &&
                            <>
                                <Divider orientation='left'> Yesterday </Divider>
                                {notifications.yesterday.map((notification, index) => (
                                    <Tooltip title='Mark as read'>
                                        <div className='notification'>
                                            {getNotificationIcon(notification.message)}
                                            <p onClick={() => readNotification(notification._id)} key={index}>
                                                {notification.message}
                                            </p>
                                        </div>
                                    </Tooltip>
                                ))}
                                <div style={{ height: '10px' }}></div>
                            </>
                        }
                        {notifications.older.length > 0 && <Divider orientation='left'> Older </Divider>}
                        {notifications.older.map((notification, index) => (
                            <Tooltip title='Mark as read'>
                                <div className='notification'>
                                    {getNotificationIcon(notification.message)}
                                    <p onClick={() => readNotification(notification._id)} key={index}>
                                        {notification.message}
                                    </p>
                                </div>
                            </Tooltip>
                        ))}
                    </div>
                }>
                    <Badge count={notificationCount} color='#11686D'>
                        <BellOutlined style={{ fontSize: 25, cursor: 'pointer' }} />
                    </Badge>
                </Popover>
            </div>
        </header>
    );
}

export default AdminNavbar;