import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import './AnalysisReporting.css'; // Import the CSS file
import { HomeFilled } from '@ant-design/icons';
import { Table, Button, Modal, message, Checkbox, Input, Select, Spin } from 'antd';
import AdminNavbar from './components/AdminNavbar';
import { employeeAttributes } from './components/Data';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const { Option } = Select;
const AnalysisReporting = () => {
	const [employeeData, setEmployeeData] = useState([]);
	const [companyName, setCompanyName] = useState('');
	const [adminCompany, setAdminCompany] = useState(null);
	const [companyId, setCompanyId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [employeeGroups, setEmployeeGroups] = useState({});
	const [selectedPosition, setSelectedPosition] = useState([]); // For storing the selected position
	const [positions, setPositions] = useState([]); // Dynamically set positi

	const fetchCompany = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				if (data.company && data.company._id) {
					const companyId = data.company._id;
					const companyName = data.company.companyName;
					setCompanyName(companyName);
					// console.log(companyId, companyName)
					setAdminCompany(companyName);
					setCompanyId(companyId);

				} else {
					message.error('Company ID not found in the response');
				}
			} else {
				message.error(data.error || 'Failed to fetch company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch company');
		}
	};
	useEffect(() => {
		fetchCompany();
	}, []);

	const formatDatesInArray = (arr) => {
		return arr.map(item => {
		  let formattedItem = { ...item };
		  formattedItem['companyName'] = companyName;
		  for (let key in formattedItem) {
			if (formattedItem.hasOwnProperty(key) && key.endsWith('Date')) {
			  let value = formattedItem[key];
			  if (moment(value, moment.ISO_8601, true).isValid()) {
				formattedItem[key] = moment(value).format('DD-MM-YYYY');
			  }
			}
		  }
		  return formattedItem;
		});
	  };

	const fetchEmployees = async () => {
		// Immediately set isLoading to true when fetch starts
		setIsLoading(true);

		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getEmployeesByCompany/${companyId}`, {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				setEmployeeData(formatDatesInArray(data));
				const uniquePositions = [...new Set(data.map(employee => employee.position))];
				setPositions(uniquePositions);
			} else {
				console.error('Error:', data.message || 'Failed to fetch employees');
			}
		} catch (error) {
			console.error('Error:', error);
		} finally {
			// Set isLoading to false when fetch is complete
			setIsLoading(false);
		}
	};


	// Fetch employees on component mount
	useEffect(() => {
		if (companyId) {
			fetchEmployees();
		}
	}, [companyId]); // Add selectedDepartments to the dependency array


	// useMemo to calculate statusCounts when employeeData changes
	const filteredEmployeeData = useMemo(() => {
		let filteredData = employeeData;
		if (selectedPosition.length > 0) {
			filteredData = filteredData.filter(employee => selectedPosition.some(position => employee.position === position));
		}
		const groupEmployeesByStatus = filteredData?.reduce((acc, employee) => {
			const status = employee.status;
			if (!acc[status]) {
				acc[status] = [];
			}
			acc[status].push(employee);
			return acc;
		}, {});

		setEmployeeGroups(groupEmployeesByStatus);
		return filteredData;
	}, [employeeData, selectedPosition]);

	const statusCounts = useMemo(() => {
		return filteredEmployeeData.reduce((acc, curr) => {
			acc[curr.status] = (acc[curr.status] || 0) + 1;
			return acc;
		}, {});
	}, [filteredEmployeeData]);
	// Prepare data for the bar chart
	const barChartData = Object.entries(statusCounts).map(([status, count]) => ({ name: status, count }));

	// Prepare data for the pie chart
	const pieChartData = Object.entries(statusCounts).map(([status, count]) => ({
		name: status,
		value: count,
	}));

	const totalEmployeeCount = useMemo(() => {
		return Object.values(statusCounts).reduce((total, count) => total + count, 0);
	}, [statusCounts]);

	const STATUS_COLORS = {
		'Active': '#11686D', // Blue
		'Sick/Accidented': '#44116D', // Orange
		'In-Active': '#6D1611', // Yellow
		'Terminated/Resigned': '#3A6D11', // Red
		'Vacation': '#3c49c3'
	};

	useEffect(() => {
		window.addEventListener('error', e => {
			if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
				const resizeObserverErrDiv = document.getElementById(
					'webpack-dev-server-client-overlay-div'
				);
				const resizeObserverErr = document.getElementById(
					'webpack-dev-server-client-overlay'
				);
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute('style', 'display: none');
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute('style', 'display: none');
				}
			}
		});
	}, []);
	const RADIAN = Math.PI / 180;

	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
				{Math.round(percent * filteredEmployeeData.length)}
			</text>
		);
	};

	return (
		<>
			<AdminNavbar />
			<div className=''>
				{isLoading ? (
					<Spin style={{ position: 'absolute', top: '50%', left: '50%' }} size='large' />
				) : (
					<div className='d-flex' style={{ padding: '3rem 5rem' }}>
						<div className='col-md-6 analysis-counts' >
							<div className='row'>
								<div className='ms-3 mb-3'>
									<Select
										className='py-2'
										showSearch
										placeholder="Select a position"
										optionFilterProp="children"
										onChange={setSelectedPosition}
										mode="multiple"
										maxTagCount={"responsive"}
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										style={{ width: '92%' }} // Match the width with the department dropdown
									>
										{positions.map(position => (
											<Option key={position} value={position}>{position}</Option>
										))}
									</Select>
								</div>

								<div className='row'>
									<div className='col-sm-6'>
										<div className='grid-cell' onClick={() => document.getElementById('active-employees')?.click()}>
											<div className='cell-label'>Active</div>
											<div className='cell-content'>{statusCounts.Active || 0}</div>
											{employeeGroups.Active && <CSVLink
												style={{ display: 'none' }}
												id='active-employees'
												data={employeeGroups.Active}
												headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
												filename='active-employees.csv'
											></CSVLink>}
										</div>
									</div>
									<div className='col-sm-6' >
										<div className='grid-cell' onClick={() => document.getElementById('sick-employees')?.click()}>
											<div className='cell-label'>Sick/Accidented</div>
											<div className='cell-content'>{statusCounts['Sick/Accidented'] || 0}</div>
											{employeeGroups['Sick/Accidented'] &&
												<CSVLink
													style={{ display: 'none' }}
													id='sick-employees'
													data={employeeGroups['Sick/Accidented']}
													headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
													filename='sick/accidented-employees.csv'
												></CSVLink>
											}
										</div>
									</div>
									<div className='col-sm-6'>
										<div className='grid-cell' onClick={() => document.getElementById('inactive-employees')?.click()}>
											<div className='cell-label'>In Active</div>
											<div className='cell-content'>{statusCounts['In-Active'] || 0}</div>
											{employeeGroups['In-Active'] &&
												<CSVLink
													style={{ display: 'none' }}
													id='inactive-employees'
													data={employeeGroups['In-Active']}
													headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
													filename='In-Active-employees.csv'
												></CSVLink>
											}
										</div>
									</div>
									<div className='col-sm-6'>
										<div className='grid-cell' onClick={() => document.getElementById('terminated-employees')?.click()}>
											<div className='cell-label'>Terminated/Resigned</div>
											<div className='cell-content'>{statusCounts['Terminated/Resigned'] || 0}</div>
											{employeeGroups['Terminated/Resigned'] &&
												<CSVLink
													style={{ display: 'none' }}
													id='terminated-employees'
													data={employeeGroups['Terminated/Resigned']}
													headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
													filename='Terminated/Resigned-employees.csv'
												></CSVLink>
											}
										</div>
									</div>
									<div className='col-sm-6'>
										<div className='grid-cell' onClick={() => document.getElementById('vacation-employees')?.click()}>
											<div className='cell-label'>Vacation</div>
											<div className='cell-content'>{statusCounts['Vacation'] || 0}</div>
											{employeeGroups['Vacation'] &&
												<CSVLink
													style={{ display: 'none' }}
													id='vacation-employees'
													data={employeeGroups['Vacation']}
													headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
													filename='Vacation-employees.csv'
												></CSVLink>
											}
										</div>
									</div>
									<div className='col-sm-6'>
										<div className='grid-cell' onClick={() => document.getElementById('all-employees')?.click()}>
											<div className='cell-label'>Total</div>
											<div className='cell-content'>{totalEmployeeCount || 0}</div>
											{totalEmployeeCount > 0 &&
												<CSVLink
													style={{ display: 'none' }}
													id='all-employees'
													data={employeeData}
													headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
													filename='all-employees.csv'
												></CSVLink>
											}
										</div>
									</div>

								</div>

							</div>
						</div>

						<div className='col-md-6'>
							<div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
								<div className='pie-chart'>
									<div style={{ overflow: 'auto' }}>
										<ResponsiveContainer width="90%" height={400}>
											<PieChart>
												<Pie
													dataKey="value"
													isAnimationActive={true}
													data={pieChartData}
													// cx="100%"
													// cy="100%"
													paddingAngle={5}
													innerRadius={'60%'}
													outerRadius={'100%'}
													fill="#8884d8"
													labelLine={false}
													label={renderCustomizedLabel}
												>
													{pieChartData.map((entry, index) => {
														return <Cell key={`cell-${index}`} fill={`rgba(17, 104, 109, ${Math.max(1 - (index * 2 / 10), 0.4)})`} />
													})}
												</Pie>
												<Tooltip />
												{/* <Legend align="left" verticalAlign="middle" layout="vertical" /> */}
											</PieChart>
										</ResponsiveContainer>
									</div>

								</div>
								{/* <div className='bar-chart'>
									<ResponsiveContainer aspect={1.5}>
										<BarChart className='mybar' width={'100%'} height={'100%'} data={barChartData} barGap={30} isAnimationActive={true}>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="name" angle={-20} textAnchor='end' />
											<YAxis />
											<Tooltip />
											<Bar dataKey="count" fill="#11686D" />
										</BarChart>
									</ResponsiveContainer>
								</div> */}

							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default AnalysisReporting;
