
import React, { useState, useEffect } from 'react';
import { message, Button, DatePicker, Select, Radio, Tooltip, Input, FloatButton, Spin } from 'antd';
import html2canvas from 'html2canvas';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { SaveOutlined, DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';
import AdminNavbar from '../components/AdminNavbar';
import dayjs from 'dayjs';
const { TextArea } = Input;


const RejoiningForm = () => {
  const { ecode } = useParams();
  const navigate = useNavigate();

  // Initial state for the form fields
  const [formData, setFormData] = useState({
    employeeName: '',
    eCode: ecode,
    qidNumber: '',
    position: '',
    dateOfEntry: '',
    dateOfReJoining: '',
    location: '',
    passportSubmissionDate: '',
    receivedBy: '',
    reJoiningStatus: '',
    reJoiningReason: '',
  });
  const [companyId, setCompanyId] = useState(null);
  const [logoComponent, setLogoComponent] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
  const [adminEmail, setAdminEmail]=useState(null);
  const [employeeInfo, setEmployeeInfo] = useState(null);


	useEffect(() => {

    if (ecode)
      handleFetchDetailsClick()
    
		fetchAndDisplayLogo().then((logo) => {
			setLogoComponent(logo);
		});
	}, []);

  const fetchCompany = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        if (data.company && data.company._id) {
          const companyId = data.company._id;
          setCompanyId(companyId);
        } else {
          message.error('Company ID not found in the response');
        }
      } else {
        message.error(data.error || 'Failed to fetch company');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to fetch company');
    }
  };
  useEffect(() => {
    fetchCompany();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
		const storedAdminEmail = localStorage.getItem('adminEmail');
		if (storedAdminEmail) {
			
			const extractedName = storedAdminEmail.split('@')[0];
			const capitalizedName = extractedName.charAt(0).toUpperCase() + extractedName.slice(1);
			setAdminEmail(capitalizedName);
			// // console.log('adminEmail',adminEmail);
		}
	}, []);

  // Add this function to fetch the employee info
  const fetchEmployeeInfo = async (employeeCode) => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getByECode`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ eCodes: employeeCode }),
			});
	
			if (!response.ok) {
        message.error('Failed to fetch employee information');
        return;
				// throw new Error('Failed to fetch employee information');
			}
	
			const employeeInfo = await response.json();
			//console.log('Fetched Employee Info:', employeeInfo);
	
			if (!employeeInfo || !Array.isArray(employeeInfo) || employeeInfo.length === 0 || !employeeInfo[0].company) {
        message.error('Invalid employee information received');
        return;
        // throw new Error('Invalid employee information received');
			}
	
			return employeeInfo;
		} catch (error) {
			console.error('Error fetching employee info:', error);
      message.error('Failed to fetch employee information');
			// throw error; // re-throw the error to handle it in the caller function
		}
	};
	const handleFetchDetailsClick = async () => {
		if (formData.eCode  ) {
			try {
				const employeeInfo = await fetchEmployeeInfo(formData.eCode);
				if (employeeInfo && employeeInfo.length > 0) {
					setFormData(employeeInfo[0]);
					setEmployeeInfo(employeeInfo);
				} else {
					message.error('Employee information not found');
				}
			} catch (error) {
				console.error(error);
				message.error('Failed to fetch employee information');
			}
		} else {
			message.warning('Please enter an Employee Code');
		}
	};

  const saveFile = async () => {
    const options = {
        margin: 10,
        filename: 'employee-rejoining-form.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    const pdfElement = document.getElementById('section-to-print');

    if (!pdfElement) {
        console.error('PDF element not found.');
        return;
    }

    try {
        const employeeInfo = await fetchEmployeeInfo(formData.eCode);
        const employeeId = employeeInfo[0]._id;
        const token = localStorage.getItem('token');

        html2pdf().from(pdfElement).set(options).outputPdf('blob').then(async (pdfBlob) => {
            const uploadData = new FormData(); // changed the name to uploadData to avoid the conflict
            uploadData.append('file', pdfBlob, 'employee-rejoining-form.pdf');
            uploadData.append('employeeId', employeeId); // Add employeeId to uploadData

            const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFileData', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                body: uploadData, // use uploadData here
            });

            const data = await response.json();
            console.log(data);
        });

    } catch (error) {
        console.error('Error:', error);
    }
}

  const handleSave = async () => {
    if (isLoading)
			return;
		setIsLoading(true);
    try {
      await fetchCompany();

      if (!companyId) {
        message.error('Failed to fetch company');
        return;
      }

      const action = "Re-Joined " + formData.reJoiningStatus;
      const status = "active";
      
      const token = localStorage.getItem('token');
      const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFile', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          rejoiningDate: dayjs(formData.dateOfReJoining).format('YYYY-MM-DD'),
          eCode: formData.eCode, // Add employeeId to uploadData
          adminEmail:adminEmail,
          action: action,
          status: status
        }), // use uploadData here
      });

      if (response.ok) {
        saveFile();
        message.success("File saved successfully");
        navigate('/admin/hr-forms');

      } else {
        console.error('Failed to save the file');
        message.error("Error saving file");
      }
			setIsLoading(false);
    } catch (error) {
      console.error(error);
			setIsLoading(false);
      message.error("Error saving file");
    }
  };

  const handleDownload = async () => {
    const pdfElement = document.getElementById('section-to-print');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'rejoining-form.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
  };

  return (
    <>
    <div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
				<AdminNavbar/>
			</div>
    <div className="center-container" style={{paddingTop: 120}}>
      {/* <p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p> */}
      <div className="employee-rejoining-form" style={{ width: '210mm' }}>

        <div className='local-leave-container' id='section-to-print' style={{border: '1px solid', height: '275mm'}}>
          <div className='form-container' style={{ border: 'none' }}>
            <div style={{position:'absolute'}}>{logoComponent}</div>
            <h3 style={{ fontWeight: 'bolder' }}>Employee Re-Joining Form</h3>
            <h4 style={{ fontWeight: 'bolder', textAlign: 'center' }}>HR/Admin Department</h4>
            <hr className='hr-line' style={{ marginTop: '30px', marginBottom: '10px' }}></hr>
            <div className="row form-fields">
              <table>
                <tr>
                  <td><label>Employee Name:</label></td>
                  <td className='px-2'><TextArea autoSize style={{borderRadius: 0, borderBottom: '1px solid black'}} id="employeeName" name="employeeName" value={formData.employeeName} onChange={handleChange} required /></td>
                  <td><label>Employee Code:</label></td>
                  <td><input style={{ width: '100%', textAlign: 'center'}} onKeyDown={(e) => e.key === "Enter" && handleFetchDetailsClick()} type="text" name="eCode" value={formData.eCode} onChange={handleChange} required /></td>
                </tr>
                <tr>
                  <td><label>QID Number:</label></td>
                  <td className='px-2'><TextArea autoSize style={{borderRadius: 0, borderBottom: '1px solid black'}} id="qidNumber" name="qidNumber" value={formData.qidNumber} onChange={handleChange} required /></td>
                  <td><label>Position:</label></td>
                  <td><TextArea autoSize style={{borderRadius: 0, borderBottom: '1px solid black'}} id="position" name="position" value={formData.position} onChange={handleChange} required /></td>
                </tr>

              </table>
            </div>
            <br></br>
            <h4 style={{ fontWeight: 'bolder' }}>Section I: Employee Details</h4>
            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr><br/>
            <div className="form-fields d-flex">
              <div className="d-flex align-items-center w-50">
                <label htmlFor="dateOfEntry">Date of Entry (in Qatar):</label>
                <input type="date" id="dateOfEntry" name="dateOfEntry" value={formData.dateOfEntry} onChange={handleChange} required />
              </div><br/>
              <div className="d-flex align-items-center w-50">

                <label htmlFor="dateOfReJoining">Date of Re-Joining:</label>
                <input style={{ marginLeft: '20px' }} type="date" id="dateOfReJoining" name="dateOfReJoining" value={formData.dateOfReJoining} onChange={handleChange} required />
              </div>
            </div>
              <div className="mt-3">
                <label htmlFor="location">Location:</label>
                <TextArea autoSize style={{borderRadius: 0, borderBottom: '1px solid black', marginLeft: '126px', width: '67%' }} type="text" id="location" name="location" value={formData.location} onChange={handleChange} required />

              </div>
            <br></br>
            <br></br>
            <h4 style={{ fontWeight: 'bolder' }}>Section II: To be filled by HR only</h4>
            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
            <div className="row form-fields">
              <div className='d-flex mt-3 gap-2'>
                <div className="w-50">
                  <label htmlFor="passportSubmissionDate">Passport <br /> Submission:</label>
                  <input style={{ marginLeft: '10px', marginTop: 5 }} type="date" id="passportSubmissionDate" name="passportSubmissionDate" value={formData.passportSubmissionDate} onChange={handleChange} required />
                </div>
                <div className="d-flex">
                  <label htmlFor="receivedBy">Received By:</label>
                  <TextArea autoSize  style={{borderRadius: 0, borderBottom: '1px solid black', marginLeft: '20px' }} type="text" id="receivedBy" name="receivedBy" value={formData.receivedBy} onChange={handleChange} required />
              </div>
              </div><br/><br/>
              <div className="col-md-12 mt-5 " style={{ display: 'flex' }}>
                <label htmlFor="reJoiningStatus">Re-Joining:</label>

                <div className="checkbox-group" id="reJoiningStatus" name="reJoiningStatus" value={formData.reJoiningStatus} onChange={handleChange} required>
                  <label>
                    <input
                      type="checkbox" value="As Expected" style={{ marginRight: '5px', marginLeft: '90px' }}
                    />
                    As Expected
                  </label>
                  <label >
                    <input
                      type="checkbox" value="Late" style={{ marginRight: '5px', marginLeft: '30px' }}
                    />
                    Late
                  </label>
                  <label>
                    <input
                      type="checkbox" value="Early" style={{ marginRight: '5px', marginLeft: '30px' }}
                    />
                    Early
                  </label>
                </div>
              </div><br/><br/><br/>
              <div className="col-md-12 mt-5 ">
                <div style={{ display: 'flex' }}>
                  <label htmlFor="reJoiningReason">Reason (if any):</label>

                  <TextArea autoSize style={{borderRadius: 0, border:'none', borderBottom: '1px solid black', marginLeft: '60px', flex: 1 }} id="reJoiningReason" name="reJoiningReason" value={formData.reJoiningReason} onChange={handleChange} />
                </div>
              </div>
            </div> <br /> <br />
            <div style={{display: 'flex' }}>
              <div className='manager-sign' style={{width: '50%'}} >
                <div style={{ width: '200px' }}>
                  <br></br>
                  <hr className='manager-hr' style={{ width: '100%' }}></hr>
                  <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>EMPLOYEE</h4>

                </div>
              </div>
              <div className='manager-sign' style={{width: '50%'}}>
                <div >
                  <br></br>
                  <hr className='manager-hr'></hr>
                  <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>HUMAN RESOURCE</h4>

                </div>
              </div>
            </div>
          </div>

          {/* <h5 style={{ fontWeight: 'bolder' }}>HR Form No: 06</h5> */}
        </div>
        {/* Save and Download buttons */}
        <FloatButton
				icon={isLoading ? <Spin/> : <SaveOutlined />}
				tooltip="Save"
				onClick={handleSave}
				style={{
					right: 200,
				}}
			/>
			{/* <Tooltip title="Print" placement='right'>
			<FloatButton
				icon={<PrinterOutlined />}
				onClick={() => window.print()}
				style={{
					right: 100,
				}}
			/>
			</Tooltip> */}
			<Tooltip title="Download" placement='top'>
				<FloatButton
					icon={<DownloadOutlined />}
					onClick={handleDownload}
					style={{
						right: 150,
					}}
				/>
			</Tooltip>
      </div>
    </div>
    </>
  );
};

export default RejoiningForm;
