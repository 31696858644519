import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import './SavedFiles.css'; // Import the CSS file for custom styling
import AdminNavbar from './components/AdminNavbar';


const SavedFiles = () => {
	const [files, setFiles] = useState([]);

	useEffect(() => {
		fetchCompany();
	}, []);

	const fetchCompany = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompanyAtt', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				if (data.company && data.company._id) {
					const companyId = data.company._id; // Retrieve the company ID from the response
					fetchFiles(companyId); // Fetch the files for the company
				} else {
					message.error('Company ID not found in the response');
				}
			} else {
				message.error(data.error || 'Failed to fetch company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch company');
		}
	};

	const fetchFiles = async (companyId) => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getCompanySalaryFiles/${companyId}`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (!response.ok) {
				message.error('Failed to fetch files');
				return
				// throw new Error('Failed to fetch files');
			}

			const data = await response.json();
			// // console.log('Files:', data); // Log the files array

			setFiles(data); // Update the files state with the fetched files
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch files');
		}
	};
	const [showFiles, setShowFiles] = useState(false);

	const handlePlusButtonClick = () => {
		setShowFiles(showFiles ? false : true);
	}

	return (
		<div>
			<AdminNavbar/>
			{/* <div className='home-header' style={{ top: '40px', left: '20px' }}>
				<Link to='/admin' style={{ color: '#11686D', paddingRight: '3vw' }}>
					<HomeFilled />
				</Link>
				Saved Files
			</div> */}
			<div className='saved-files mt-5'>
				<h3 style={{textAlign:'left'}}>
					<Button icon={showFiles ? <MinusOutlined /> : <PlusOutlined />} style={{backgroundColor:'transparent', border:'none', marginRight:'20px', marginBottom:'20px'}} onClick={handlePlusButtonClick}/>
					Payroll Files
				</h3>
				{showFiles && (
						Array.isArray(files) && files.length > 0 ? (
							<ul className='savedfiles-list'>
								{files.map((file) => (
									<li key={file._id}>
										<a
											href={`https://hrms-5u7j.onrender.com/admin/uploads/${file.filename}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{file.originalname}
										</a>
									</li>
								))}
							</ul>
						) : (
							<p style={{margin: 0, paddingLeft: 52}} className='savedfiles-list'>No files found</p>
						)
				)}
			</div>
		</div>
	);
};

export default SavedFiles;