import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button, Modal, message, Checkbox, Input, Select, Tag, Popconfirm, Tooltip, Popover } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { DeleteOutlined, ShrinkOutlined, FileAddOutlined, FileExcelOutlined, PlusCircleOutlined, ArrowsAltOutlined, FilterFilled, FilterOutlined, MoreOutlined, DownloadOutlined } from '@ant-design/icons';
import { utils, read } from 'xlsx';
import moment from 'moment';
import './EmployeeDataDashboard.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AdminNavbar from './components/AdminNavbar';
import { employeeAttributeDataTypes, employeeAttributeLabels, employeeAttributes } from './components/Data';
import dayjs from 'dayjs';


const EmployeeDataDashboard = () => {
	const [employees, setEmployees] = useState([]);
	const [exportConfirmed, setExportConfirmed] = useState(false);
	const [file, setFile] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [openFilter, setOpenFilter] = useState(false);
	const navigate = useNavigate();
	const [companyName, setCompanyName] = useState('');
	const [companyId, setCompanyId] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(100);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [importLoading, setImportLoading] = useState(false);
	const fileInputRef = useRef(null);
	const [minimize, setMinimize] = useState(true);
	const csvLinkEl = useRef();
	const sampleEl = useRef();
	const [filters, setFilters] = useState([]);
	const [filterColumn, setFilterColumn] = useState('');
	const [filterValue, setFilterValue] = useState([]);

	const fetchCompany = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				if (data.company && data.company._id) {
					const companyId = data.company._id;
					const companyName = data.company.companyName;
					setCompanyName(companyName);
					setCompanyId(companyId);
					// fetchDepartments(companyName)
				} else {
					message.error('Company ID not found in the response');
				}
			} else {
				message.error(data.error || 'Failed to fetch company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch company');
		}
	};
	useEffect(() => {
		fetchCompany();
	}, []);

	const formatDatesInArray = (arr) => {
		return arr.map(item => {
			let formattedItem = { ...item };
			formattedItem['companyName'] = companyName;
			for (let key in formattedItem) {
				if (formattedItem.hasOwnProperty(key) && key.endsWith('Date')) {
					let value = formattedItem[key];
					if (moment(value, moment.ISO_8601, true).isValid()) {
						formattedItem[key] = moment(value).format('DD-MM-YYYY');
					}
				}
			}
			return formattedItem;
		});
	};

	const fetchEmployees = useCallback(async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getEmployeesByCompany/${companyId}`, {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				setEmployees(formatDatesInArray(data));
				setDataLoaded(true);
			} else {
				message.error(data.message || 'Failed to fetch employees');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch employees');
		}
	}, [companyId]); // Add fetchDepartments as a dependency

	useEffect(() => {
		if (companyId) {
			fetchEmployees(); // Call it from here only if companyId is not null
		}
	}, [fetchEmployees, companyId]); // Add fetchEmployees and companyId as dependencies


	const handleDeleteEmployee = async (employeeId) => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/${employeeId}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				fetchEmployees();
			} else if (response.status === 403) {
				message.error('Insufficient permissions');
			} else {
				message.error(data.message || 'Failed to delete employee');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to delete employee');
		}
	};

	const handleAddEmployeeClick = () => {
		navigate('/admin/addEmployee');
	};

	const importEmployees = () => {
		const reader = new FileReader();
		reader.onload = async (evt) => {
			/* Parse data */
			const bstr = evt.target.result;
			const wb = read(bstr, { type: 'binary' });

			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];

			/* Convert array of arrays */
			const data = utils.sheet_to_json(ws, { header: 1 });
			// console.log("companyId", companyId);
			try {
				const token = localStorage.getItem('token');
				const response = await fetch('https://hrms-5u7j.onrender.com/admin/import', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,

					},
					body: JSON.stringify({ employees: data, companyID: companyId }),
				});

				const responseData = await response.json();

				if (!response.ok) {
					message.error(responseData.message || 'Failed to import employees');
					return
				}
				message.success('Employees imported successfully!');
			} catch (error) {
				console.error('Error:', error);
				message.error(error.message);
			} finally {
				setImportLoading(false);
				// console.log(importLoading);
			}
		};
		reader.readAsBinaryString(file);
	}

	const handleFileChange = (e) => {
		setFile(e.target.files[0]);
		setImportLoading(true);
		// console.log(importLoading);
	};

	const handleImportClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	useEffect(() => {
		if (file) {
			importEmployees();
		}
	}, [file]);

	const filteredEmployees = employees.filter((employee) => {
		if (searchQuery) {
			return Object.values(employee).join('').toLowerCase().includes(searchQuery.toLowerCase());
		}
		return filters.every((filter) => {
			return filter.values.some(value =>
				employee[filter.column]?.toLowerCase().startsWith(value.toLowerCase())
			);
		});
	});

	const removeFilter = (index) => {
		setFilters(filters.filter((_, i) => i !== index));
	}

	const confirmFilters = () => {
		// // console.log(filterColumn, filterValue, filters)
		setFilters([...filters, { column: filterColumn, values: filterValue.map((value) => value.trim()) }])
		setFilterColumn('')
		setFilterValue([])
	}


	const addFilterValue = (value) => {
		setFilterValue(value)
	}

	const exportToCSV = () => {
		// Assuming you've correctly set selectedAttributes and employees
		// Directly setting exportConfirmed here
		setExportConfirmed(true);

		// Programmatically click the CSVLink element to trigger the download
		if (csvLinkEl.current) {
			csvLinkEl.current.link.click();
		}
	};

	const downloadSample = () => {
		if (sampleEl.current)
			sampleEl.current.link.click();
	}

	const generateColumns = () => {
		return Object.keys(employeeAttributeLabels).map(key => {
			let column = {
				title: employeeAttributeLabels[key],
				dataIndex: key,
				key: key,
				width: 200,
				ellipsis: true
			};

			//   if (key === 'refNo' || key === 'refName')
			// 	column.fixed = 'left';

			if (key === 'employeeName') {
				column.fixed = 'left';
				column.render = (_, record) => (
					<Link className={minimize ? 'cell-cutoff' : ''} to={`/admin/employee-details/${record._id}`} style={{ whiteSpace: 'nowrap', textDecoration: 'none' }}>
						{record.employeeName}
					</Link>
				);
			}

			if (key === 'eCode') {
				column.render = (_, record) => (
					<div className='d-flex justify-content-between align-items-center'>
						<div className='d-flex gap-2 align-items-center'>
							{record.personalFiles.length === 0 ?
								<Tooltip title="Personal files missing">
									<FileExcelOutlined />
								</Tooltip>
								: <div style={{ width: 14 }}></div>}
							{record.eCode}
						</div>
						<Popover
							placement='right'
							trigger='click'
							content={
								<div className='employee-shortcuts pe-5'>
									<Link to={`/admin/hr-forms/leave-application/${record.eCode}`}>Apply for leave</Link>
									<Link to={`/admin/hr-forms/employment-termination/${record.eCode}`}>Apply for Termination</Link>
									<Link to={`/admin/hr-forms/rejoining-form/${record.eCode}`}>Apply for rejoining</Link>
									<Link to={`/admin/hr-forms/sick-leave/${record.eCode}`}>Apply for Sick Leave</Link>
									<Link to={`/admin/hr-forms/warning-letter/${record.eCode}`}>Warning Letter</Link>
									<Link to={`/admin/employee-details/${record._id}?tab=2`}>Employee Files</Link>
									<Link to={`/admin/employee-details/${record._id}?tab=1`}>Employee Timeline</Link>
									<Popconfirm
										title="Delete the Employee"
										description="Are you sure to delete this employee?"
										onConfirm={() => handleDeleteEmployee(record._id)}
										okText="Yes"
										cancelText="No"
									>
										<Link style={{ color: 'red', cursor: 'pointer' }}>
											Delete Employee
										</Link>
									</Popconfirm>
								</div>
							}
						>
							<MoreOutlined />
						</Popover>
					</div>
				);
				column.fixed = 'left';
			}

			if (key === 'refName') {
				column.render = (_, record) => (
					<p className={minimize ? 'm-0 cell-cutoff' : 'm-0'} style={{ whiteSpace: 'nowrap' }}>
						{record.refName}
					</p>
				);
			}

			// if (key.toLowerCase().includes('date')) {
			// 	column.render = date => moment(date).format('YYYY-MM-DD');
			// }

			if (key === 'companyName') {
				column.render = (_, record) => (
					<p className='m-0'>
						{companyName}
					</p>
				);
			}

			if (key === 'status') {
				column.render = (_, record) => (
					<p className='m-0'
						style={{ color: record.status === 'Active' ? '#10ca10' : record.status === 'Terminated/Resigned' ? 'red' : 'orange' }}
					>
						{record.status}
					</p>
				);
			}

			const dataType = employeeAttributeDataTypes[key];

			if (dataType === 1) { // Number (String)
				column.sorter = (a, b) => (a[key] ?? '').localeCompare(b[key] ?? '', undefined, { numeric: true });
			} else if (dataType === 2) { // Alphabetic String
				column.sorter = (a, b) => (a[key] ?? '').localeCompare(b[key] ?? '');
			} else if (dataType === 3) { // Date
				column.sorter = (a, b) => {
					const dateA = dayjs(a[key], 'DD-MM-YYYY');
					const dateB = dayjs(b[key], 'DD-MM-YYYY');

					if (!dateA.isValid() && dateB.isValid()) return -1;
					if (dateA.isValid() && !dateB.isValid()) return 1;
					if (!dateA.isValid() && !dateB.isValid()) return 0;

					return dateA.diff(dateB);
				};
			}
			else if (dataType === 4) { // Number
				column.sorter = (a, b) => (a[key] ?? 0) - (b[key] ?? 0);
			}

			return column;
		});
	};

	console.log(filteredEmployees)
	const columns = generateColumns();

	return (
		<div className=''>
			<AdminNavbar />
			<div className='dashboard mt-3'>
				<div className='d-flex justify-content-between my-2'>
					<Input.Search
						placeholder='Search employees'
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						allowClear
						style={{ width: '500px' }}
					/>
					<div className='d-flex gap-3'>
						<Popconfirm
							title="Download Sample File"
							description="Do you wish to download the sample file?"
							onConfirm={downloadSample}
							onCancel={handleImportClick}
							cancelText="Import"
							okText="Download"
						>
							<Tooltip title='Import Employees'>
								<Button type='text' shape='circle' icon={<FileAddOutlined style={{ fontSize: 20 }} />} />
							</Tooltip>
						</Popconfirm>
						<CSVLink
							style={{ display: 'none' }}
							data={[]}
							headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
							filename="sample.csv"
							ref={sampleEl}
						/>
						<Tooltip title='Add Employee'>
							<Button type='text' shape='circle' icon={<PlusCircleOutlined style={{ fontSize: 20 }} />} onClick={handleAddEmployeeClick} />
						</Tooltip>
						{!minimize ?
							<Tooltip title='Minimize'>
								<Button type='text' shape='circle'><ShrinkOutlined style={{ fontSize: 20 }} onClick={() => setMinimize(true)} /></Button>
							</Tooltip> :
							<Tooltip title='Maximize'>
								<Button type='text' shape='circle'><ArrowsAltOutlined style={{ fontSize: 20 }} onClick={() => setMinimize(false)} /></Button>
							</Tooltip>
						}
						<Tooltip title='Export'>
							<Button type='text' shape='circle' icon={<DownloadOutlined style={{ fontSize: 20 }} />} onClick={exportToCSV} />
						</Tooltip>
						<Tooltip title='Filter'>
							{openFilter ?
								<Button type='text' shape='circle' icon={<FilterFilled style={{ fontSize: 20 }} />} onClick={() => setOpenFilter(!openFilter)} />
								:
								<Button type='text' shape='circle' icon={<FilterOutlined style={{ fontSize: 20 }} />} onClick={() => setOpenFilter(!openFilter)} />
							}
						</Tooltip>
						{exportConfirmed && (
							<CSVLink
								style={{ display: 'none' }}
								data={employees}
								headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
								filename="employees.csv"
								ref={csvLinkEl}
							/>
						)}
					</div>

				</div>
				<div>

				</div>
				<div className={`table-wrapper${openFilter ? '-open' : ''} d-flex justify-content-between gap-1`}>
					<div className='table'>
						<Table
							dataSource={filteredEmployees}
							// style={{ width: '100%' }}
							columns={columns}
							rowKey="_id"
							loading={!dataLoaded}
							className="table-class"
							// rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
							pagination={{
								current: currentPage,
								pageSize: currentSize,
								total: filteredEmployees.length,
								onChange: (page) => setCurrentPage(page),
								onShowSizeChange: (current, size) => setCurrentSize(size),
							}}
							scroll={{
								x: 'max-content',
								y: `calc(90vh - 250px)`
							}}
						/>
					</div>
					<div className={`filter-container ${openFilter ? 'open' : ''}`}>
						<div>
							{filters.map(({ column, values }, index) => (
								<div key={index}>
									<div className='d-flex justify-content-between'>
										<p>{employeeAttributeLabels[column]}</p>
										<Button shape='circle' type='text' onClick={() => removeFilter(index)}>x</Button>
									</div>
									{values.map((value) => (
										<Tag key={value} >
											{value}
										</Tag>
									))}
									<br /><br />
								</div>
							))}
							<p>Filter by</p>
							<Select
								showSearch
								onChange={(value) => setFilterColumn(value)}
								value={filterColumn}
								style={{
									width: '100%',
									marginBottom: 10
								}}
								placeholder="Search to Select"
								optionFilterProp="children"
								filterOption={(input, option) => (option?.label.toLowerCase() ?? '').startsWith(input.toLowerCase())}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
								}
								options={Object.entries(employeeAttributeLabels)
									.filter(([key]) => key !== 'companyName') // Filter out companyName
									.map(([key, value]) => ({
										value: key,
										label: value,
									}))}
							/>
							<Select
								mode="tags"
								style={{
									width: '100%',
									marginBottom: 10
								}}
								placeholder="value"
								notFoundContent={null}
								value={filterValue}
								onChange={addFilterValue}
							/>
							<Button onClick={confirmFilters} style={{ float: 'right' }}>Confirm</Button>
						</div>
					</div>
				</div>
			</div>
			<input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".csv" onChange={handleFileChange} />
		</div>
	);
};

export default EmployeeDataDashboard;
