import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SaveOutlined, DownloadOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';
import { DatePicker, FloatButton, Input, Spin, Tooltip, message } from 'antd';
import AdminNavbar from '../components/AdminNavbar';
import moment from 'moment';
import dayjs from 'dayjs';

const { TextArea } = Input;


const LoanRequest = () => {
    const { ecode } = useParams();
    const navigate = useNavigate();

    const [logoComponent, setLogoComponent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [adminEmail, setAdminEmail] = useState(null);
    const [formData, setFormData] = useState({
        employeeName: '',
        eCode: ecode,
        startingDate: null,
        qidNumber: '',
        amountRequested: '',
    });

    useEffect(() => {
        const storedAdminEmail = localStorage.getItem('adminEmail');
        if (storedAdminEmail) {

            const extractedName = storedAdminEmail.split('@')[0];
            const capitalizedName = extractedName.charAt(0).toUpperCase() + extractedName.slice(1);
            setAdminEmail(capitalizedName);
            // // console.log('adminEmail',adminEmail);
        }
    }, []);

    useEffect(() => {
        if (ecode)
            fetchEmployeeInfo(ecode);
        fetchAndDisplayLogo().then((logo) => {
            setLogoComponent(logo);
        });
    }, []);

    const handleDownload = async () => {
        const pdfElement = document.getElementById('section-to-print');

        if (!pdfElement) {
            console.error('PDF element not found.');
            return;
        }

        const options = {
            margin: 0,
            filename: 'loan-request.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(pdfElement).set(options).save();
    };

    const fetchEmployeeInfo = async (employeeCode) => {
        // console.log(employeeCode)
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getByECode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify({ eCodes: employeeCode }),
            });

            if (!response.ok) {
                message.error('Failed to fetch employee information');
                return;
                // throw new Error('Failed to fetch employee information');
            }

            const employeeInfo = await response.json(); // This line extracts the body of the response
            // console.log(employeeInfo)
            if (employeeInfo.length > 0)
                setFormData({ ...employeeInfo[0], hiringDate: dayjs(employeeInfo[0].hiringDate) });
            return employeeInfo;
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const saveFile = async () => {
        const options = {
            margin: 10,
            filename: 'loan-request.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        const pdfElement = document.getElementById('section-to-print');

        if (!pdfElement) {
            console.error('PDF element not found.');
            return;
        }

        try {
            const employeeInfo = await fetchEmployeeInfo(formData.eCode);
            const employeeId = employeeInfo[0]._id;
            const token = localStorage.getItem('token');

            html2pdf().from(pdfElement).set(options).outputPdf('blob').then(async (pdfBlob) => {
                const uploadData = new FormData(); // changed the name to uploadData to avoid the conflict
                uploadData.append('file', pdfBlob, 'loan-request.pdf');
                uploadData.append('employeeId', employeeId); // Add employeeId to uploadData

                const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFileData', {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    body: uploadData, // use uploadData here
                });

                const data = await response.json();
                console.log(data);
            });

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleSave = async () => {
        if (formData.amountRequested <= 0 || formData.amountRequested == undefined) {
            message.error('Please fill loan amount');
            return
        }
        setIsLoading(true);

        const action = formData.amountRequested + " Loan Request Submitted on " + dayjs().format('YYYY-MM-DD');

        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFile', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eCode: formData.eCode,
                    adminEmail: adminEmail,
                    action: action,
                    loanAmount: formData.amountRequested
                }),
            });

            if (response.ok) {
                saveFile();
                message.success('File saved successfully');
                navigate('/admin/hr-forms');
            } else {
                console.error('Failed to save the file');
                message.error('Error saving file');
            }
        } catch (error) {
            console.error('Error in handleSave:', error);
            message.error('Failed to save the file');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
                <AdminNavbar />
            </div>
            <div className="center-container" style={{ paddingTop: 120 }}>
                <div className="disciplinary-action-form">
                    <div className='local-leave-container' style={{ height: '296mm', width: '210mm' }}>
                        <div className='form-container' id='section-to-print' style={{ border: '1px solid black', padding: '10px' }}>
                            <div style={{ position: 'absolute' }}>{logoComponent}</div>
                            <h3 style={{ fontWeight: 'bolder', border: 'none', marginBottom: '0px' }}>Loan Application Form</h3>
                            <h4 style={{ fontWeight: 'bolder', textAlign: 'center' }} >HR/Admin Department</h4><br />
                            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '30px' }}></hr>
                            <div className="form-fields d-flex" style={{ flexWrap: 'wrap', rowGap: 10 }}>
                                <div className="w-50">
                                    <label>Employee Name:</label>
                                    <TextArea name='employeeName' onChange={handleChange} value={formData.employeeName} autoSize style={{ marginLeft: '30px', width: '50%', borderRadius: 0 }} />
                                </div>
                                <div className="w-50">
                                    <label>Code:</label>
                                    <Input onPressEnter={() => fetchEmployeeInfo(formData.eCode)} name='eCode' onChange={handleChange} value={formData.eCode} style={{ marginLeft: '90px', width: '50%', borderRadius: 0, textAlign: 'center' }} type="text" />
                                </div>
                                <div className="w-50">
                                    <label>QID Number:</label>
                                    <TextArea value={formData.qidNumber} onChange={handleChange} name='qidNumber' autoSize style={{ marginLeft: '61px', width: '50%', borderRadius: 0 }} type="text" />
                                </div>
                                <div className="w-50">
                                    <label>Date of Joining:</label>
                                    <DatePicker name='startingDate' value={formData.hiringDate} onChange={(date) => setFormData({ ...formData, hiringDate: date })} style={{ marginLeft: '20px', width: '50%' }} />
                                </div>
                            </div>
                            <br></br>
                            <h4 style={{ fontWeight: 'bolder' }}>Section I: Details</h4>
                            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                            {/* <br></br> */}
                            <div className="d-flex flex-wrap  form-fields salary-fields">
                                <div >
                                    <label>Monthly Salary:</label>
                                    <label >QR.</label>
                                    <input type="text" />
                                </div>
                                <div>
                                    <label >Amount Requested: Qr.</label>
                                    <input type="number" value={formData.amountRequested} onChange={handleChange} name='amountRequested' />
                                </div>
                                <div>
                                    <label>Repayment Details:</label>
                                    <TextArea autoSize style={{ borderRadius: 0 }} type="text" />
                                </div>
                                <div>
                                    <label>Last Loan Availed (if any):</label>
                                    <TextArea autoSize style={{ borderRadius: 0 }} type="text" />
                                </div>
                            </div>
                            <br></br>
                            <h4 style={{ fontWeight: 'bolder' }}>Section II: For HR Use Only</h4>
                            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                            <br></br>
                            <div className="form-fields" style={{ height: '350px' }}>
                                <div className="d-flex ">
                                    <label>Last Loan Repaid Details(if any):</label>
                                    <TextArea autoSize type="text" style={{ marginLeft: '10px', width: '62%', borderRadius: 0 }} />
                                </div>
                                {/* <br></br>
                                <br></br> */}
                                <div className="mt-3 d-flex">
                                    <label>Comments:</label>
                                    <TextArea autoSize type="text" style={{ marginLeft: '10px', width: '85%', borderRadius: 0 }} />
                                </div>
                            </div>
                            {/* <br></br>
                            <br></br> */}
                            <div className='d-flex mb-4'>
                                <div className='manager-sign w-50'>
                                    <div style={{ width: '200px' }}>
                                        {/* <br></br> */}
                                        <hr className='manager-hr' style={{ width: '100%' }}></hr>
                                        <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>Employee</h4>

                                    </div>
                                </div>
                                <div className='manager-sign w-50'>
                                    <div >
                                        {/* <br></br> */}
                                        <hr className='manager-hr'></hr>
                                        <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>Operations Manager</h4>

                                    </div>
                                </div>
                            </div>
                            {/* <br></br> */}
                            <div className='note' style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <h4><strong>Note:</strong> Applicant should successfully complete 1 year of continuous service.</h4>
                            </div>
                        </div>
                        {/* <h4 style={{ fontWeight: 'bolder' }}>HR Form No: 11</h4> */}
                    </div>
                    <FloatButton
                        icon={isLoading ? <Spin /> : <SaveOutlined />}
                        tooltip="Save"
                        onClick={handleSave}
                        style={{
                            right: 200,
                        }}
                    />
                    <Tooltip title="Download" placement='top'>
                        <FloatButton
                            icon={<DownloadOutlined />}
                            onClick={handleDownload}
                            style={{
                                right: 150,
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
        </>
    );
};

export default LoanRequest;
