import { useLocation } from "react-router-dom"

const LookerStudio = () => {
    const location = useLocation()
    const checkPage = () => {
        if (location.pathname.endsWith('looker-studio'))
            return 'd-flex'
        return 'd-none'
    }

    return (
        <div className={`p-3 w-100 ${checkPage()} justify-content-center`}>
            <iframe
                width="1400"
                height="800"
                src="https://lookerstudio.google.com/embed/reporting/633ab6ad-510c-4a86-abab-fd2d4cab0882/page/p0acC"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
            </iframe>

            <div style={{transform: 'rotate(90deg)', transformOrigin: 'center center', display: 'none', visibility: 'hidden'}} id="section-to-print">
                <iframe
                    width="1300"
                    height="500"
                    src="https://lookerstudio.google.com/embed/reporting/633ab6ad-510c-4a86-abab-fd2d4cab0882/page/p0acC"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen
                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
                </iframe>
            </div>
        </div>
    );
}

export default LookerStudio;