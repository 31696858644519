import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, Button, DatePicker, message, Row, Col, Select, Upload, Spin } from 'antd';
import { UploadOutlined, UserOutlined, DeleteOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import dayjs from 'dayjs';

const { TextArea } = Input;

const EmployeeDetails = () => {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [departments, setDepartments] = useState([]);
	const [adminCompany, setAdminCompany] = useState(null);
	const [customAttributes, setCustomAttributes] = useState([]);
	const [cn, scn] = useState([]);
	const [customAttributeValues, setCustomAttributeValues] = useState({}); // Add this state
	const [profilePicture, setProfilePicture] = useState({});
	const [workTenure, setWorkTenure] = useState({});
	const [pictureLoaded, setPictureLoaded] = useState(false)
	const eCodeInputRef = useRef(null);
	const [removeLoading, setRemoveLoading] = useState(false);

	useEffect(() => {
		const fetchAdminCompany = async () => {
			try {
				const token = localStorage.getItem('token');
				const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				if (response.ok) {
					const companyName = data.company.companyName;
					setAdminCompany(companyName);
					scn(companyName);
					fetchDepartments(companyName)
					// console.log("companyName", companyName);
					return companyName; // Return the company name directly
				} else {
					console.error('Failed to fetch admin company:', data.error);
					return Promise.reject(data.error);
				}
			} catch (error) {
				console.error('Error:', error);
				// throw error;
			}
		};


		const fetchDepartments = async (adminCompany) => {
			try {
				const token = localStorage.getItem('token');
				const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getDepartmentsE/${adminCompany}`, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				// console.log('Departments Data:', data);
				if (response.ok) {
					const departments = data; // Assign the response data directly
					setDepartments(departments);
				} else {
					console.error('Failed to fetch departments:', data.error);
					// throw new Error(data.error);
				}
			} catch (error) {
				console.error('Error:', error);
				// throw error;
			}
		};



		const fetchEmployee = async () => {
			try {
				const token = localStorage.getItem('token');
				const response = await fetch(`https://hrms-5u7j.onrender.com/admin/${id}`,
					{
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
						},
					});
				const data = await response.json();
				if (response.ok) {
					const parsedData = {
						...data,
						hiringDate: data.hiringDate ? dayjs(data.hiringDate) : null,

						startingDate: data.startingDate ? dayjs(data.startingDate) : null,
						terminationDate: data.terminationDate ? dayjs(data.terminationDate) : null,
						qidExpiryDate: data.qidExpiryDate ? dayjs(data.qidExpiryDate) : null,
						healthCardExpiryDate: data.healthCardExpiryDate ? dayjs(data.healthCardExpiryDate) : null,
						healthCertificateExpiryDate: data.healthCertificateExpiryDate ? dayjs(data.healthCertificateExpiryDate) : null,
						driverLicenseExpiryDate: data.driverLicenseExpiryDate ? dayjs(data.driverLicenseExpiryDate) : null,
						passportExpiryDate: data.passportExpiryDate ? dayjs(data.passportExpiryDate) : null,
						visaExpiryDate: data.visaExpiryDate ? dayjs(data.visaExpiryDate) : null,
						birthDate: data.birthDate ? dayjs(data.birthDate) : null,
						rejoiningDate: data.rejoiningDate ? dayjs(data.rejoiningDate) : null,

					};
					// console.log('fetched data from DB', parsedData);
					const profilePicture = data.profilePicture;
					setProfilePicture(profilePicture);
					setPictureLoaded(true);
					const customAttributeValues = {};
					for (const attr of parsedData.customAttributes) {
						customAttributeValues[`customAttribute_${attr.attribute}`] = attr.value || ''; // Use the attribute ID as the key
					}
					setCustomAttributeValues(customAttributeValues);
					nameWidthChange(data.employeeName);
					// Set the custom attribute values directly in the form
					form.setFieldsValue({

						...parsedData,
						...customAttributeValues,
					});
					if (data.hiringDate) {
						const currentDate = moment();
						const diffDuration = moment.duration(currentDate.diff(data.hiringDate));
						const years = diffDuration.years();
						const months = diffDuration.months();
						const days = diffDuration.days();

						setWorkTenure({ years, months, days });
					}
				} else {
					console.error('Failed to fetch employee:', data.error);
					message.error(data.error || 'Failed to fetch employee');
					setPictureLoaded(true);
				}
			} catch (error) {
				console.error('Error:', error);
				setPictureLoaded(true);
				message.error('Failed to fetch employee');
			}
		};



		const fetchEmployeeCustomAttributes = async (companyName) => {
			try {
				const token = localStorage.getItem('token');
				const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getCustomAttributes/${companyName}`, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				// console.log('Custom Attributes Data:', data);
				if (response.ok) {
					if (Array.isArray(data)) {
						// Update the customAttributes state here
						setCustomAttributes(data);

						// Initialize the form fields with default values
						const customAttributeValues = {};
						data.forEach((attr) => {
							customAttributeValues[attr._id] = attr.attributeValue || ''; // Use an empty string if attributeValue is null
						});
						form.setFieldsValue(customAttributeValues);
					} else {
						console.error('Expected an array for custom attributes, but got:', data);
					}
				} else {
					console.error('Failed to fetch custom attributes:', data.error);
					// throw new Error(data.error);
				}
			} catch (error) {
				console.error('Error:', error);
				// throw error;
			}
		};

		const fetchData = async () => {
			try {
				const companyName = await fetchAdminCompany();
				scn(companyName);
				// console.log('here companyName', companyName);
				form.setFieldsValue({ Company: companyName });

				// Fetch custom attributes only if they haven't been fetched yet
				if (customAttributes.length === 0) {
					await fetchEmployeeCustomAttributes(companyName);
				}
				await fetchEmployee();
				scn(companyName);
			} catch (error) {
				console.error('Error:', error);
			}
		};

		fetchData();
	}, [id, form]);

	const handleUpdateEmployee = async (values) => {
		try {
			const token = localStorage.getItem('token');

			// Prepare the custom attributes data
			const customAttributesData = customAttributes.map((attr) => ({
				customAttrId: attr._id,
				name: attr.name,
				value: values[`customAttribute_${attr._id}`], // Use the custom attribute ID as the form field name
			}));

			// Include the custom attributes data in the values object
			const updatedValues = {
				hiringDate: values.hiringDate ? dayjs(values.hiringDate) : null,
				startingDate: values.startingDate ? dayjs(values.startingDate) : null,
				terminationDate: values.terminationDate ? dayjs(values.terminationDate) : null,
				qidExpiryDate: values.qidExpiryDate ? dayjs(values.qidExpiryDate) : null,
				healthCardExpiryDate: values.healthCardExpiryDate ? dayjs(values.healthCardExpiryDate) : null,
				healthCertificateExpiryDate: values.healthCertificateExpiryDate ? dayjs(values.healthCertificateExpiryDate) : null,
				driverLicenseExpiryDate: values.driverLicenseExpiryDate ? dayjs(values.driverLicenseExpiryDate) : null,
				passportExpiryDate: values.passportExpiryDate ? dayjs(values.passportExpiryDate) : null,
				visaExpiryDate: values.visaExpiryDate ? dayjs(values.visaExpiryDate) : null,
				birthDate: values.birthDate ? dayjs(values.birthDate) : null,
				...values,
				customAttributes: customAttributesData,
			};

			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/${id}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(updatedValues),
			});
			// console.log("values before sending", updatedValues);
			const data = await response.json();
			// console.log('values after sending :', data);
			if (response.ok) {
				message.success('Succesfully updated employee');
				// navigate('/admin/employeeDataDashboard');

				// Update the customAttributeValues state after successful update
				const updatedCustomAttributeValues = { ...customAttributeValues };
				customAttributes.forEach((attr) => {
					updatedCustomAttributeValues[`customAttribute_${attr._id}`] = values[`customAttribute_${attr._id}`];
				});
				setCustomAttributeValues(updatedCustomAttributeValues);
			} else {
				message.error(data.error || 'Failed to update employee');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to update employee');
		}
	};


	// Handle profile picture upload
	const handleProfilePictureUpload = (file) => {
		// console.log(file);
		setProfilePicture(file);
	};

	const fetchEmployeeInfo = async (e) => {
		e.preventDefault();
		const token = localStorage.getItem('token');
		const eCodeValue = form.getFieldValue('eCode');
		try {
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getByECode`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ eCodes: eCodeValue }),
			});

			if (!response.ok) {
				message.error('Failed to fetch employee information');
				return
				// throw new Error('Failed to fetch employee information');
			}

			const employeeInfo = await response.json();
			//console.log('Fetched Employee Info:', employeeInfo);

			if (employeeInfo.length > 0)
				navigate('/admin/employee-details/' + employeeInfo[0]._id);
		} catch (error) {
			console.error('Error fetching employee info:', error);
			message.error('Failed to fetch employee information');
			// throw error; // re-throw the error to handle it in the caller function
		}
	};

	const [inputwidth, setinputWidth] = useState(50);

  const nameWidthChange = (name) => {
    let length = name.length;
	console.log(length);
    if (length === 0) {
      setinputWidth(200);
    }
    if (length < 35) {
       setinputWidth(50 + name.length * 20);
    }
  };

	const removeProfilePicture = async () => {
		if (removeLoading)
			return
		setRemoveLoading(true);
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/removeProfilePicture', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ _id: id }),
			})

			if (!response.ok) {
				message.error('Failed to remove profile picture');
				setRemoveLoading(false);
				return
			}

			message.success('Profile picture removed successfully');
			setProfilePicture(null);
			setRemoveLoading(false);
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to remove profile picture');
		}
	}

	return (

		<div className='pt-3 w-100 emp-details-container' id='section-to-print'>
			{/* <h2>Update Employee Details</h2> */}
			<Form onFinish={handleUpdateEmployee} form={form} style={{ paddingRight: 50 }} layout="vertical">

				<Row gutter={12} >
					<Col span={6} style={{ height: 0 }}>
						<Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							{pictureLoaded && profilePicture ? (
								<div className='profile-container'>
									<Button type='text' icon={removeLoading ? <Spin /> : <DeleteOutlined />} onClick={removeProfilePicture} className='delete-button'></Button>
									<img
										onClick={() => document.getElementById('profilePicture').click()}
										src={`https://hrms-5u7j.onrender.com/admin/uploads/${profilePicture.filename}`}
										alt="Profile Picture"
										className='image-container'
									/>
								</div>
							) : (
								<div className='placeholder-container' style={{ cursor: 'pointer' }} onClick={() => document.getElementById('profilePicture').click()}>
									{pictureLoaded ?
										<UserOutlined style={{ fontSize: '8rem', color: 'gray' }} /> :
										<Spin size='medium' />}
								</div>
							)}
							{/* Add an Upload component for profile picture */}
							<Upload
								style={{ display: 'none' }}
								// id='profilePicture'
								showUploadList={false}
								customRequest={async ({ file }) => {
									try {
										const formData = new FormData();
										formData.append('file', file);

										// Get the file extension from the originalname
										const fileExtension = file.name.split('.').pop();

										formData.append('filename', `${id}.${fileExtension}`); // Append the extension
										formData.append('originalname', file.name);
										const token = localStorage.getItem('token');
										const response = await fetch(`https://hrms-5u7j.onrender.com/admin/upload-profile/${id}`, {
											method: 'POST',
											headers: {
												Authorization: `Bearer ${token}`,
											},
											body: formData,
										});

										const data = await response.json();
										console.log('data: ', data)

										if (response.status === 200) {
											message.success('Profile picture uploaded successfully');
											handleProfilePictureUpload(data.profilePicture);
										} else {
											message.error('Failed to upload profile picture');
										}
									} catch (error) {
										console.error('Error uploading profile picture:', error);
										message.error('An error occurred while uploading the profile picture');
									}
								}}
							>

								<Button id='profilePicture' className='image-container' style={{ display: 'none' }} icon={<UploadOutlined />}>Upload Profile Picture</Button>

							</Upload>

						</Form.Item>
					</Col>
					<Col span={18}>
						<Row gutter={12}>
							<Col span={24} >
								<h2 style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
									<Form.Item name={'employeeName'}>
										<Input placeholder='Employee Name' onChange={(e) => nameWidthChange(e.target.value)} 
										style={{ width: inputwidth, minWidth: 300, padding: 0, border: 'none', fontSize: '2rem', fontWeight: 600 }}/>
									</Form.Item>
									 - &nbsp;
									{adminCompany}
								</h2>
							</Col>
							<Col span={8}>
								<Form.Item name="eCode" label='E-Code'>
									<Input.Search className='details-search' onPressEnter={fetchEmployeeInfo} onSearch={fetchEmployeeInfo} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="refNo" label="Reference Number" >
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="refName" label="Reference Name" >
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="personalNumber" label="Personal Number" >

									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="companyNumber" label="Company Number" >
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="status" label="Select a status">
									<Select
										className={`${form.getFieldValue('status')} status-select-${form.getFieldValue('status') === 'Active' ? 'green' : form.getFieldValue('status') === 'Terminated/Resigned' ? 'red' : 'orange'}`}
									>
										<Select.Option value="Active">Active</Select.Option>
										<Select.Option value="Sick/Accidented">Sick/Accidented</Select.Option>
										<Select.Option value="Vacation"> Vacation</Select.Option>
										<Select.Option value="In-Active">In-Active</Select.Option>
										<Select.Option value="Terminated/Resigned">Terminated/Resigned</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="nationality" label="Nationality" >
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="qidNumber" label="QID Number" >
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="healthCard" label="Health Card" >
									<Input />
								</Form.Item>

							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col span={6}>
						<Form.Item name="passportNumber" label="Passport Number" >
							<Input />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="driverLicenseType" label="Driver License Type" >
							<Input />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="visaNumber" label="Visa Number" >
							<Input />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="contract" label="Contract" >
							<Input />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="bankAccountIBAN" label="Bank Account IBAN" >
							<Input />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="religion" label="Religion" >
							<Input />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="maritalStatus" label="Marital Status">
							<Input />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="hiringDate"
							label="Hiring Date"
							getValueFromEvent={(date, dateString) => dateString}
							normalize={(value) => (value ? moment.utc(value, 'YYYY-MM-DD') : null)}
						>
							<DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="startingDate"
							label="Starting Date"
							getValueFromEvent={(date, dateString) => dateString}
							normalize={(value) => {
								console.log(value);
								return value ? moment.utc(value, 'YYYY-MM-DD') : null;
							}}
						>
							<DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="terminationDate"
							label="Termination Date"
							getValueFromEvent={(date, dateString) => dateString}
							normalize={(value) => (value ? moment.utc(value, 'YYYY-MM-DD') : null)}
						>
							<DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="qidExpiryDate" label="QID Expiry Date"
							getValueFromEvent={(date, dateString) => dateString}
							normalize={(value) => (value ? moment.utc(value, 'YYYY-MM-DD') : null)}
						>
							<DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />



						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="healthCardExpiryDate" label="Health Card Expiry"
							getValueFromEvent={(date, dateString) => dateString}
							normalize={(value) => (value ? moment.utc(value, 'YYYY-MM-DD') : null)}

						>

							<DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />


						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="healthCertificateExpiryDate" label="Health Certificate Expiry"
							getValueFromEvent={(date, dateString) => dateString}
							normalize={(value) => (value ? moment.utc(value, 'YYYY-MM-DD') : null)}
						>
							<DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />


						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="driverLicenseExpiryDate" label="Driver License Expiry"
							getValueFromEvent={(date, dateString) => dateString}
							normalize={(value) => (value ? moment.utc(value, 'YYYY-MM-DD') : null)}
						>
							<DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />

						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="passportExpiryDate" label="Passport Expiry Date" >
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>

					</Col>
					<Col span={6}>
						<Form.Item name="visaExpiryDate" label="Visa Expiry Date">
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>

					</Col>
					<Col span={6}>
						<Form.Item name="birthDate" label="Date of Birth"
							getValueFromEvent={(date, dateString) => dateString}
							normalize={(value) => (value ? moment.utc(value, 'YYYY-MM-DD') : null)}
						>
							<DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
						</Form.Item>

					</Col>
					<Col span={6}>
						<Form.Item name="lifeInsurance" label="Life Insurance" >
							<Input Number style={{ width: '100%' }} />

						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="basicSalary" label="Basic Salary" >
							<Input Number style={{ width: '100%' }} />
						</Form.Item>

					</Col>
					<Col span={6}>
						<Form.Item name="recruitmentSource" label="Recruitment Source" >
							<Input />
						</Form.Item>


					</Col>
					<Col span={6}>
						<Form.Item name="department" label="Select a department">
							<Select>
								{departments.map((department) => (
									<Select.Option key={department._id} value={department._id}>
										{department.departmentName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>




					</Col>
					<Col span={6}>
						<Form.Item label='Work Tenure'>
							<Input
								placeholder={`${workTenure.years} years, ${workTenure.months} months, ${workTenure.days} days`}
								disabled
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="position" label="Select a position">
							<Select>
								<Select.Option value="None">None</Select.Option>
								<Select.Option value="Chief Executive Officer (CEO)">Chief Executive Officer (CEO)</Select.Option>
								<Select.Option value="Chief Operating Officer (COO)">Chief Operating Officer (COO)</Select.Option>
								<Select.Option value="Chief Financial Officer (CFO)">Chief Financial Officer (CFO)</Select.Option>
								<Select.Option value="Chief Technology Officer (CTO)">Chief Technology Officer (CTO)</Select.Option>
								<Select.Option value="Chief Marketing Officer (CMO)">Chief Marketing Officer (CMO)</Select.Option>
								<Select.Option value="Chief Human Resources Officer (CHRO)">Chief Human Resources Officer (CHRO)</Select.Option>
								<Select.Option value="President">President</Select.Option>
								<Select.Option value="Vice President">Vice President</Select.Option>
								<Select.Option value="Director">Director</Select.Option>
								<Select.Option value="Manager">Manager</Select.Option>
								<Select.Option value="Supervisor">Supervisor</Select.Option>
								<Select.Option value="Team Leader">Team Leader</Select.Option>
								<Select.Option value="Administrative Assistant">Administrative Assistant</Select.Option>
								<Select.Option value="Executive Assistant">Executive Assistant</Select.Option>
								<Select.Option value="Office Manager">Office Manager</Select.Option>
								<Select.Option value="Receptionist">Receptionist</Select.Option>
								<Select.Option value="Clerk">Clerk</Select.Option>
								<Select.Option value="Sales Manager">Sales Manager</Select.Option>
								<Select.Option value="Sales Representative">Sales Representative</Select.Option>
								<Select.Option value="Account Manager">Account Manager</Select.Option>
								<Select.Option value="Marketing Manager">Marketing Manager</Select.Option>
								<Select.Option value="Marketing Coordinator">Marketing Coordinator</Select.Option>
								<Select.Option value="Digital Marketing Specialist">Digital Marketing Specialist</Select.Option>
								<Select.Option value="Content Creator">Content Creator</Select.Option>
								<Select.Option value="Social Media Manager">Social Media Manager</Select.Option>
								<Select.Option value="Customer Service Representative">Customer Service Representative</Select.Option>
								<Select.Option value="Business Development Manager">Business Development Manager</Select.Option>
								<Select.Option value="Accountant">Accountant</Select.Option>
								<Select.Option value="Accounting Manager">Accounting Manager</Select.Option>
								<Select.Option value="Controller">Controller</Select.Option>
								<Select.Option value="Financial Analyst">Financial Analyst</Select.Option>
								<Select.Option value="Payroll Specialist">Payroll Specialist</Select.Option>
								<Select.Option value="Auditor">Auditor</Select.Option>
								<Select.Option value="Bookkeeper">Bookkeeper</Select.Option>
								<Select.Option value="Tax Specialist">Tax Specialist</Select.Option>
								<Select.Option value="Human Resources Manager">Human Resources Manager</Select.Option>
								<Select.Option value="HR Coordinator">HR Coordinator</Select.Option>
								<Select.Option value="Recruiter">Recruiter</Select.Option>
								<Select.Option value="Training and Development Specialist">Training and Development Specialist</Select.Option>
								<Select.Option value="Payroll Manager">Payroll Manager</Select.Option>
								<Select.Option value="Benefits Coordinator">Benefits Coordinator</Select.Option>
								<Select.Option value="IT Manager">IT Manager</Select.Option>
								<Select.Option value="Network Administrator">Network Administrator</Select.Option>
								<Select.Option value="Software Developer">Software Developer</Select.Option>
								<Select.Option value="Systems Analyst">Systems Analyst</Select.Option>
								<Select.Option value="Database Administrator">Database Administrator</Select.Option>
								<Select.Option value="Help Desk Technician">Help Desk Technician</Select.Option>
								<Select.Option value="Cybersecurity Specialist">Cybersecurity Specialist</Select.Option>
								<Select.Option value="Data Scientist">Data Scientist</Select.Option>
								<Select.Option value="Operations Manager">Operations Manager</Select.Option>
								<Select.Option value="Project Manager">Project Manager</Select.Option>
								<Select.Option value="Logistics Coordinator">Logistics Coordinator</Select.Option>
								<Select.Option value="Production Manager">Production Manager</Select.Option>
								<Select.Option value="Quality Assurance Manager">Quality Assurance Manager</Select.Option>
								<Select.Option value="Inventory Manager">Inventory Manager</Select.Option>
								<Select.Option value="Procurement Specialist">Procurement Specialist</Select.Option>
								<Select.Option value="Research Scientist">Research Scientist</Select.Option>
								<Select.Option value="Product Manager">Product Manager</Select.Option>
								<Select.Option value="R&D Engineer">R&D Engineer</Select.Option>
								<Select.Option value="Lab Technician">Lab Technician</Select.Option>
								<Select.Option value="Mechanical Engineer">Mechanical Engineer</Select.Option>
								<Select.Option value="Electrical Engineer">Electrical Engineer</Select.Option>
								<Select.Option value="Civil Engineer">Civil Engineer</Select.Option>
								<Select.Option value="Software Engineer">Software Engineer</Select.Option>
								<Select.Option value="Chemical Engineer">Chemical Engineer</Select.Option>
								<Select.Option value="Production Worker">Production Worker</Select.Option>
								<Select.Option value="Machine Operator">Machine Operator</Select.Option>
								<Select.Option value="Assembly Line Worker">Assembly Line Worker</Select.Option>
								<Select.Option value="Quality Control Inspector">Quality Control Inspector</Select.Option>
								<Select.Option value="Plant Manager">Plant Manager</Select.Option>
								<Select.Option value="General Counsel">General Counsel</Select.Option>
								<Select.Option value="Attorney">Attorney</Select.Option>
								<Select.Option value="Paralegal">Paralegal</Select.Option>
								<Select.Option value="Legal Assistant">Legal Assistant</Select.Option>
								<Select.Option value="Compliance Officer">Compliance Officer</Select.Option>
								<Select.Option value="Trainer">Trainer</Select.Option>
								<Select.Option value="Instructional Designer">Instructional Designer</Select.Option>
								<Select.Option value="Teacher/Instructor">Teacher/Instructor</Select.Option>
								<Select.Option value="Safety Officer">Safety Officer</Select.Option>
								<Select.Option value="Occupational Health Nurse">Occupational Health Nurse</Select.Option>
								<Select.Option value="Environmental Health Officer">Environmental Health Officer</Select.Option>
								<Select.Option value="Customer Support Representative">Customer Support Representative</Select.Option>
								<Select.Option value="Technical Support Specialist">Technical Support Specialist</Select.Option>
								<Select.Option value="Graphic Designer">Graphic Designer</Select.Option>
								<Select.Option value="Web Designer">Web Designer</Select.Option>
								<Select.Option value="Copywriter">Copywriter</Select.Option>
								<Select.Option value="Art Director">Art Director</Select.Option>
								<Select.Option value="Mechanic">Mechanic</Select.Option>
								<Select.Option value="Helper Mechanic">Helper Mechanic</Select.Option>
								<Select.Option value="Bike Rider">Bike Rider</Select.Option>
								<Select.Option value="Car Driver">Car Driver</Select.Option>
								<Select.Option value="Camp Boss">Camp Boss</Select.Option>
								<Select.Option value="Cleaner">Cleaner</Select.Option>
								<Select.Option value="Plumber">Plumber</Select.Option>
								<Select.Option value="Electrician">Electrician</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name='note' label='Notes'>
							<Input />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="rejoiningDate" label="Rejoining Date"
							getValueFromEvent={(date, dateString) => dateString}
							normalize={(value) => (value ? moment.utc(value, 'YYYY-MM-DD') : null)}
						>
							<DatePicker disabled style={{ width: '100%' }} format="YYYY-MM-DD" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name='loanAmount' label='Loan Amount'>
							<Input disabled />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="accomodation" label="Accomodation">
							<Select>
								<Select.Option value="yes">Yes</Select.Option>
								<Select.Option value="no">No</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 'calc(((100vh - 660px) / 15) + 15px)' }}>
						<Button className='create-button m-0' type="primary" htmlType="submit">
							Update Employee
						</Button>
					</Col>
					{customAttributes.map((attr) => (

						<Col span={6} key={attr._id}>
							<Form.Item
								name={`customAttribute_${attr._id}`}
								initialValue={customAttributeValues[`customAttribute_${attr._id}`]}
								label={attr.name}
							>
								<Input />
							</Form.Item>
						</Col>
					))}

					{/* <Form.Item label='Profile Picture'>
								<Button onClick={pictureButtonClick} style={{ width: '100%' }}>Change</Button>
							</Form.Item> */}
				</Row>



			</Form>
		</div >

	);
};

export default EmployeeDetails;
