import './ManVacationReports.css';

import React, { useState, useEffect, useCallback } from 'react';
import { Table, Select, message, Button } from 'antd';
import { Link } from 'react-router-dom';
import { HomeFilled } from '@ant-design/icons';
import moment from 'moment';
import Papa from 'papaparse';
import Navbar from './components/Navbar';

const ManVacationReports = () => {
    const [employees, setEmployees] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [companies, setCompanies] = useState([]);
    const { Option } = Select;
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 100;
    const [selectedMonth, setSelectedMonth] = useState(moment().month()); // This defaults to the current month.
    const storedManagerEmail = localStorage.getItem('managerEmail') || '';
    const [managerEmail, setManagerEmail] = useState(storedManagerEmail);
    const [vacationType, setVacationType] = useState('scheduled'); // 'scheduled' or 'current'

    const fetchCompany = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/manager/companies', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setCompanies(data.companies);
            } else {
                message.error(data.error || 'Failed to fetch company');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch company');
        }
    };

    const fetchEmployeesByCompany = async (companyId) => {
        setDataLoaded(false);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getEmployeesByCompany/${companyId}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setDataLoaded(true);
                setEmployees(data);
            } else {
                message.error(data.message || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch employees');
        }
    };

    const fetchAllEmployees = useCallback(async () => {
        setDataLoaded(false);
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getEmployeesByManager/${managerEmail}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                // // console.log(data);
                setDataLoaded(true);
                setEmployees(data);
            } else {
                message.error(data.message || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch employees');
        }
    }, []);

    const handleCompanyChange = (companyId) => {
        if (companyId !== null) {
            fetchEmployeesByCompany(companyId);
            setCompanyId(companyId);
        } else {
            fetchAllEmployees();
            setCompanyId(null);
        }
    };


    useEffect(() => {
        fetchCompany();
        fetchAllEmployees();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (companyId !== null) {
                await fetchEmployeesByCompany(companyId);
            } else {
                await fetchAllEmployees();
            }
            setDataLoaded(true); // Set data loaded to true after fetching data
        };

        setDataLoaded(false); // Set data loaded to false before fetching data
        fetchData();
    }, [companyId, selectedMonth, vacationType]); // Add companyId as a dependency here

    const filterEmployees = () => {
        if (vacationType === 'scheduled') {
            return employees.filter(employee =>
                employee.vacations.some(vacation =>
                    moment(vacation.startDate).month() === selectedMonth &&
                    moment(vacation.startDate).year() === moment().year()
                )
            );
        } else { // 'current'
            return employees.filter(employee =>
                employee.status === 'Vacation'
            )

        }
    };




    const getVacationCounts = () => {
        let alreadyOnVacation = 0;
        let willGoOnVacation = 0;

        employees.forEach(employee => {
            if (employee.status === 'Vacation') {
                alreadyOnVacation += 1;
            }
            if (employee.vacations.some(vacation => {
                if (vacation.startDate) {
                    const startDate = moment(vacation.startDate);
                    if (startDate.month() === selectedMonth && startDate.year() === moment().year()) {
                        return true;
                    }
                }
                return false;
            })) {
                willGoOnVacation += 1;
            }
        });

        return { alreadyOnVacation, willGoOnVacation };
    };



    const handleMonthChange = (month) => {
        setSelectedMonth(month);
        if (companyId !== null) {
            fetchEmployeesByCompany(companyId);
        }
    };

    const { alreadyOnVacation, willGoOnVacation } = getVacationCounts();

    const columns = [
        {
            title: 'SN',
            dataIndex: 'serial',
            key: 'serial',
            fixed: 'left',

            render: (_, __, index) => (currentPage - 1) * pageSize + index + 1
        },
        {
            title: 'E-CODE',
            dataIndex: 'eCode',
            key: 'eCode',
            fixed: 'left',
        },
        {
            title: 'EMPLOYEE NAME',
            dataIndex: 'employeeName',
            key: 'employeeName',
            fixed: 'left',
            // render: text => <div style={{ wordWrap: 'break-word', maxWidth: '200px' }}>{text}</div>
        },

        {
            title: 'VACATION START DATE',
            dataIndex: 'vacations',
            key: 'startDate',
            width : 200,
            render: vacations => {
                const startDate = vacations[0]?.startDate;
                return startDate ? moment(startDate).format('YYYY-MM-DD') : 'N/A';
            },
        },

        {
            title: 'VACATION END DATE',
            dataIndex: 'vacations',
            key: 'endDate',
            width : 200,
            render: vacations => {
                const endDate = vacations[0]?.endDate;
                return endDate ? moment(endDate).format('YYYY-MM-DD') : 'N/A';
            },
        },

        {
            title: 'DAYS',
            dataIndex: 'vacations',
            key: 'days',
            render: vacations => {
                const startDate = vacations[0]?.startDate;
                const endDate = moment(vacations[0]?.endDate);
				const diffDuration = endDate?.diff(startDate, 'days');
                return diffDuration;
            },
        },
    ];

    const filteredEmployees = filterEmployees();

    const exportToCSV = () => {
        const displayedData = filteredEmployees.map(emp => {
            return {
                serial: emp.serial,
                eCode: emp.eCode,
                employeeName: emp.employeeName,
                status: emp.status,
                startDate: moment(emp.startDate).format('YYYY-MM-DD'),
            };
        });

        const csv = Papa.unparse(displayedData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'employees_vacation_data.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <>
            <Navbar />
            <div className=''>
                <div className='dashboard'>
                    {/* <h3 style={{ color: '#11686D' }}>Employee Vacation Reports</h3> */}
                    {/* <div className="row">
                        <div className="col-sm-6">
                            <div className='dashboard-banner-cell'>
                                <div className="cell-label">Already On Vacation</div>
                                <div className="cell-content">{getVacationCounts().alreadyOnVacation}</div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className='dashboard-banner-cell'>
                                <div className="cell-label">Will Go On Vacation</div>
                                <div className="cell-content">{getVacationCounts().willGoOnVacation}</div>
                            </div>
                        </div>
                    </div> */}

                    <div style={{ padding: '2vh 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Select
                            style={{ width: '35%', fontFamily: 'var(--font)' }}
                            placeholder="Select company"
                            value={companyId}
                            onChange={(value) => {
                                handleCompanyChange(value);
                                handleMonthChange(moment().month());
                            }}
                        >
                            <Option value={null}>All Companies</Option>
                            {companies.map((company) => (
                                <Option key={company._id} value={company._id}>
                                    {company.companyName}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            style={{ width: '20%', fontFamily: 'var(--font)' }}
                            value={selectedMonth}
                            onChange={(value) => handleMonthChange(value)}
                        >
                            {moment.months().map((month, index) => (
                                <Option key={index} value={index}>
                                    {month}
                                </Option>
                            ))}
                        </Select>
                        <div>
                            <Select
                                defaultValue="scheduled"
                                style={{ width: 202 }}
                                onChange={(value) => setVacationType(value)}
                            >
                                <Option value="scheduled">Scheduled Vacations</Option>
                                <Option value="current">Current Vacations</Option>
                            </Select>
                        </div>
                        <Button
                            style={{ backgroundColor: "#6FB555", margin: "1vh 1vw", padding: '0 1vw', height: '23px' }}
                            type="primary"
                            onClick={exportToCSV}
                        >
                            Export to CSV
                        </Button>
                        <div>


                        </div>

                    </div>
                    <div style={{ width: '100%', marginTop: '1rem' }}>
                        <Table
                            pagination={{ pageSize: 100, current: currentPage, onChange: (page) => setCurrentPage(page) }}
                            dataSource={filterEmployees()}
                            loading={!dataLoaded}
                            columns={columns}
                            scroll={{
								x: 'max-content',
								y: `calc(70vh - 250px)`
							}}
                            size='middle'
                            // rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                        />

                    </div>

                </div>
                {/* <div className="home-logo">
                    <Link to="/manager-router" style={{ color: '#11686D', paddingRight: '3vw' }}>
                        <HomeFilled />
                    </Link>

                </div> */}

            </div>
        </>
    );
};

export default ManVacationReports;
