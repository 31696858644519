import React, { useState, useEffect, useCallback } from 'react';
import { Table, Select, message, Button } from 'antd';
import { Link } from 'react-router-dom';
import { HomeFilled } from '@ant-design/icons';
import moment from 'moment';
import Papa from 'papaparse';

import './ManDateReports.css'; // Please create a new CSS file for this module.
import Navbar from './components/Navbar';
const ManDateReports = () => {
    const [employees, setEmployees] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [companies, setCompanies] = useState([]);
    const { Option } = Select;
    const [filterType, setFilterType] = useState('withinAMonth'); // default to "expiry within a month"
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);  // Maintain the current page in the state
    const pageSize = 100;
    const storedManagerEmail = localStorage.getItem('managerEmail') || '';
    const [managerEmail, setManagerEmail] = useState(storedManagerEmail);
    const fetchCompany = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/manager/companies', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setCompanies(data.companies);
            } else {
                message.error(data.error || 'Failed to fetch company');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch company');
        }
    };

    const fetchEmployeesByCompany = async (companyId) => {
        setDataLoaded(false);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getEmployeesByCompany/${companyId}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setDataLoaded(true);
                setEmployees(data);
            } else {
                message.error(data.message || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch employees');
        }
    };
    const fetchAllEmployees = useCallback(async () => {
        setDataLoaded(false);
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getEmployeesByManager/${managerEmail}`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            const data = await response.json();

            if (response.ok) {
                setDataLoaded(true);
                setEmployees(data);
            } else {
                message.error(data.message || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch employees');
        }
    }, []);

    const handleCompanyChange = (companyId) => {
        if (companyId !== null) {
            fetchEmployeesByCompany(companyId);
            setCompanyId(companyId);
        } else {
            fetchAllEmployees(); // Fetch all employees if no specific company is selected.
            setCompanyId(null);
        }
    };

    useEffect(() => {
        fetchCompany();
        fetchAllEmployees();
    }, []);

    const isExpiringWithinNextMonth = (dateString) => {
        const currentDate = moment();
        const expiryDate = moment(dateString);
        const differenceInDays = expiryDate.diff(currentDate, 'days');
        return differenceInDays >= 0 && differenceInDays <= 30;
    };

    const isExpired = (dateString) => {
        const currentDate = moment();
        const expiryDate = moment(dateString);
        return expiryDate.isBefore(currentDate);
    };

    const filterEmployees = () => {
        // First, filter out terminated or resigned employees
        const activeEmployees = employees.filter(employee =>
            !["terminated", "resigned"].some(status =>
                employee.status?.toLowerCase().includes(status)
            )
        );

        if (filterType === 'withinAMonth') {
            return activeEmployees.filter(employee =>
                expiryColumns.some(column =>
                    employee[column.dataIndex] && isExpiringWithinNextMonth(employee[column.dataIndex])
                )
            );
        } else {
            return activeEmployees.filter(employee =>
                expiryColumns.some(column =>
                    employee[column.dataIndex] && isExpired(employee[column.dataIndex])
                )
            );
        }
    };

    const checkDateValidity = (dateString) => {
        if (filterType === 'withinAMonth') {
            return isExpiringWithinNextMonth(dateString);
        } else {
            return isExpired(dateString);
        }
    };
    const expiryColumns = [

        {
            title: 'QID Expiry',
            dataIndex: 'qidExpiryDate',
            key: 'qidExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Health Card Expiry',
            dataIndex: 'healthCardExpiryDate',
            key: 'healthCardExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Health Certificate Expiry',
            dataIndex: 'healthCertificateExpiryDate',
            key: 'healthCertificateExpiryDate',
            // fixed: 'left',
            width: 250,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Driver License Expiry',
            dataIndex: 'driverLicenseExpiryDate',
            key: 'driverLicenseExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Passport Expiry',
            dataIndex: 'passportExpiryDate',
            key: 'passportExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Visa Expiry',
            dataIndex: 'visaExpiryDate',
            key: 'visaExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        }
    ];
    const generateColumns = () => {
        let validColumns = expiryColumns.filter(column => {
            let hasValidDate = false;
            for (let employee of filteredEmployees) {
                if (filterType === 'withinAMonth' && isExpiringWithinNextMonth(employee[column.dataIndex])) {
                    hasValidDate = true;
                    break;
                }
                if (filterType === 'expired' && isExpired(employee[column.dataIndex])) {
                    hasValidDate = true;
                    break;
                }
            }
            return hasValidDate;
        });

        return [
            {
                title: 'SN',
                dataIndex: 'serial',
                key: 'serial',
                // fixed: 'left',
                render: (_, __, index) => (currentPage - 1) * pageSize + index + 1
            },
            { title: 'E-CODE', dataIndex: 'eCode', key: 'eCode'},
            {
                title: 'EMPLOYEE NAME',
                dataIndex: 'employeeName',
                key: 'employeeName',
                // fixed: 'left',
                width: 200,
                render: text => <div style={{ wordWrap: 'break-word', maxWidth: '200px' }}>{text}</div>
            },

            ...validColumns
        ];
    };


    const filteredEmployees = filterEmployees();
    const columns = generateColumns();

    const exportToCSV = () => {
        // Extracting data that's actually displayed in the table
        const displayedData = filteredEmployees.map(emp => {
            const obj = {};
            columns.forEach(col => {
                // Only add the column if there's a dataIndex (ignoring columns with render functions only)
                if (col.dataIndex) {
                    const value = emp[col.dataIndex];
                    if (typeof value === 'string' && value && value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/) && checkDateValidity(value)) {
                        obj[col.dataIndex] = moment(value).format('YYYY-MM-DD');
                    } else if (value && !value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/)) {
                        obj[col.dataIndex] = value;  // If it's not a date column, just add it to the CSV data
                    } else {
                        obj[col.dataIndex] = "";  // If it's a date column and is not valid, set it as blank
                    }
                }
            });
            return obj;
        });

        const csv = Papa.unparse(displayedData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'employees_data.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };




    return (
        <>
            <Navbar />
            <div className=''>
                <div className='dashboard'>
                    {/* <h3 style={{ color: '#11686D' }}>Employee Date Reports</h3> */}
                    <div style={{ padding: ' 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Select

                            style={{ width: '35%', fontFamily: 'var(--font)' }}
                            placeholder="Select company"
                            value={companyId}
                            onChange={(value) => handleCompanyChange(value)}
                        >
                            <Option value={null}>All Companies</Option>
                            {companies.map((company) => (
                                <Option key={company._id} value={company._id}>
                                    {company.companyName}
                                </Option>
                            ))}
                        </Select>

                        <Select

                            style={{ width: '35%', fontFamily: 'var(--font)' }}
                            placeholder="Filter by expiry date"
                            value={filterType}
                            onChange={(value) => setFilterType(value)}
                        >
                            <Option value="withinAMonth">Expiry within a month</Option>
                            <Option value="expired">Expired</Option>
                        </Select>
                        <Button style={{ backgroundColor: "#6FB555", margin: "1vh 1vw", padding: '0 1vw', height: '23px' }} type="primary" onClick={exportToCSV}>
                            Export to CSV
                        </Button>

                    </div>
                    <div className='table-wrapper'>
                        <div className='table'>
                            <Table
                                pagination={{
                                    pageSize: 100,
                                    current: currentPage,
                                    onChange: (page) => setCurrentPage(page)
                                }}
                                dataSource={filteredEmployees}
                                loading={dataLoaded ? false : !dataLoaded}
                                columns={columns}
                                rowKey="_id"
                                scroll={{
                                    x: 'max-content',
                                    y: `calc(90vh - 250px)`
                                }}
                            // rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className='home-logo'>
                    <Link to='/manager-router' style={{ color: '#11686D', paddingRight: '3vw' }}><HomeFilled /></Link>
                    Date Reports
                </div>
                <div className="smalllogo-image">
                <img src='/crootive.png' alt='Crootive' className='smalllogo-image' />
                </div> */}
            </div>
        </>
    );
};

export default ManDateReports;



