import React, { useEffect, useState } from 'react';
import './ContractResidential.css';
import html2canvas from 'html2canvas';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';

const ContractResidential = () => {
    const [logoComponent, setLogoComponent] = useState(null);

	// useEffect(() => {
	// 	fetchAndDisplayLogo().then((logo) => {
	// 		setLogoComponent(logo);
	// 	});
	// }, []);

    const handleDownload = async () => {
        const pdfElement = document.querySelector('.local-leave-container');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'contract-residential-form.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
    };

    return (
        <div className="center-container">
            <p className='home-header' style={{ position: 'fixed' }}>
                <Link to='/manager/hr-forms
' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p>
            <div className="disciplinary-action-form">
                <div className='local-leave-container' style={{ height: '296mm', width: '210mm' }}>
                    <div className='form-container'>
                    <div style={{position:'absolute'}}>{logoComponent}</div>
                        <h2 style={{ fontWeight: 'bolder', border: 'none' }}>Residence Renewal</h2>
                        <h3 style={{ fontWeight: 'bolder', textAlign: 'center', border: 'none', marginBottom: '10px' }}>Request Form</h3>
                        <h4 style={{ textAlign: 'center' }} >Human Resources Department</h4>
                        <hr className='hr-line' style={{ marginBottom: '30px', marginTop: '30px' }}></hr>
                        <div className="row ">
                            <div className="col-md-6 ">
                                <label>Employee Name:</label>
                                <input style={{ marginLeft: '20px', width: '50%' }} type="text" />
                            </div>
                            <div className="col-md-6 ">
                                <label>Employee Code:</label>
                                <input style={{ marginLeft: '20px', width: '50%' }} type="text" />
                            </div>
                            <div className=" col-md-12" style={{ paddingTop: '20px' }}>
                                <label>Rider System Name:</label>
                                <input style={{ marginLeft: '25px', width: '70%' }} type="text" />
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <h4 style={{ fontWeight: 'bolder' }}>Section I: Contract Details (to be filled by employee)</h4>
                        <hr className='hr-line' style={{ marginTop: '5px', marginBottom: '10px' }}></hr>
                        <br></br>
                        <div className="row">
                            <label className='col-md-4'>Joining Date:</label>
                            <input style={{ width: '60%' }} className='col-md-8' type="text" />
                        </div>
                        <br />
                        <div className="row">
                            <label className='col-md-4'>Residence Number:</label>
                            <input style={{ width: '60%' }} className='col-md-8' type="text" />
                        </div><br />
                        <div className="row">
                            <label className='col-md-4'>Remarks:</label>
                            <textarea className="col-md-8" rows={2} style={{ resize:'none', width: '60%', border:'none', borderBottom: '1px solid black', marginLeft: '0' }} />
                        </div>
                        <div className='row' style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                            <div className='manager-sign col-md-6'>
                                <div style={{ width: '200px' }}>
                                    <br></br>
                                    <hr className='manager-hr' style={{ width: '100%' }}></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>Employee</h4>

                                </div>
                            </div>
                            <div className='manager-sign col-md-6'>
                                <div >
                                    <br></br>
                                    <hr className='manager-hr'></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>HR OPERATIONS MANAGER</h4>
                                </div>
                            </div>
                        </div>
                        <div className='note'>
                            <h4><strong>Note:</strong> If employee decided to resign after renewal of residential ID charges will apply accordingly.</h4>
                        </div>
                    </div>

                    <h4 style={{ fontWeight: 'bolder' }}>HR Form No: 07</h4>
                    <br></br>
                </div>
                <div className="button-group">
                    <button className="download-button" onClick={handleDownload}>
                        Download
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ContractResidential;
