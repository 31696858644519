import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ManagerLogin.css';
import {
    LoadingOutlined
} from '@ant-design/icons';

const ManagerLogin = () => {
    const [managerEmail, setManagerEmail] = useState('');
    const [managerPassword, setManagerPassword] = useState('');
    const [loginPressed, setLoginPressed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoginPressed(true);
        setIsLoading(true);
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/manager/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ managerEmail, managerPassword }),
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.setItem('managerEmail', managerEmail);
                localStorage.setItem('token', data.token);

                setManagerEmail('');
                setManagerPassword('');
                navigate('/manager');
            } else {
                setIsLoading(false);
                setErrorMessage('Username or password is incorrect');
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error:', error);
            setErrorMessage('Login Failed');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        }
    };

    const handleSignup = () => {
        navigate('/managerSignUp');
    };

    const handleAdminLogin = () => {
        navigate('/adminLogin');
    };

    return (
        <div id='login-container-manager'>
            <div className='container'>
                <img src='/login2.svg' alt='Login' className='login-image' />
                <h2 className='heading'>Manager Login</h2>
                <form className='form'>
                    <input
                        className={`input-field ${(!managerEmail && loginPressed) && 'invalid-input'}`}
                        type='text'
                        value={managerEmail}
                        onChange={e => setManagerEmail(e.target.value)}
                        placeholder='username'
                        required
                    />
                    <input
                        className={`input-field ${(!managerPassword && loginPressed) && 'invalid-input'}`}
                        type='password'
                        value={managerPassword}
                        onChange={e => setManagerPassword(e.target.value)}
                        placeholder='password'
                        required
                    />
                    <div className='error-message'>{errorMessage}</div>
                    <button className='login-button' onClick={handleLogin}>
                        {isLoading ? <LoadingOutlined /> : 'LOGIN'}
                    </button>
                    {/* <button className='linkButton' onClick={handleSignup}>
                        Don’t have an account? Sign up here
                    </button> <br /> */}
                    <button className='linkButton' onClick={handleAdminLogin}>
                        Admin Login
                    </button>
                </form>
            </div>
            <img src='/login-group.png' alt='Login' className='login-group-image' />
            <div className='login-image-container'>
            </div>
        </div>
    );
};



export default ManagerLogin;
