import { useParams, useNavigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import { useEffect, useState } from "react";
import { Button, DatePicker, Input, message, Modal, Select, Timeline } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import moment from "moment";
import dayjs from "dayjs";
const { Option } = Select;

const VehichleDetails = () => {
	const { id } = useParams();
	const [vehicle, setVehicle] = useState([]);
	const [details, setDetails] = useState({});
	const [vehicleFiles, setVehicleFiles] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const url = 'https://hrms-5u7j.onrender.com';
	const navigate = useNavigate();

	useEffect(() => {
		fetchVehicle();
		fetchVehicleFiles();
	}, [id]);

	const fetchVehicle = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(url + '/manager/fetchVehicleById/' + id, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			console.log('vehicle ', data);
			if (response.ok) {
				setVehicle(
					data.timeline.sort((a, b) => new Date(b.date) - new Date(a.date))
				);
				setDetails({...data, istemaraExpiry: data.istemaraExpiry ? dayjs(data.istemaraExpiry): null});
			} else {
				message.error(data.error || 'Failed to fetch vehicle');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch vehicle');
		}
	}

	const eventcolor = (event) => {
		if (event.includes('unassigned')) {
			return 'red'
		} else if (event.includes('assigned')) {
			return 'green'
		}
		return 'blue'
	}

	const fetchVehicleFiles = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(url + `/manager/getVehicleFiles/${id}`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (!response.ok) {
				message.error('Failed to fetch vehicle files');
				// throw new Error('Failed to fetch employee files');
				return
			}

			const data = await response.json();
			setVehicleFiles(data);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleFileSelect = (event) => {
		setSelectedFiles(event.target.files);
	};
	const handleFileUpload = async () => {
		try {
			if (selectedFiles.length === 0) {
				return;
			}

			const formData = new FormData();
			// formData.append('employeeId', employeeId);

			for (const file of selectedFiles) {
				formData.append('files', file);
			}
			// console.log(employeeId);

			const token = localStorage.getItem('token');
			const response = await fetch(url + `/manager/saveVehicleFiles/${id}`, {
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + token,
				},
				body: formData,
			});

			if (response.ok) {
				// Successfully saved files, you can perform any necessary actions here
				// console.log('Files uploaded successfully');
				// Refresh the personal files section after successful upload
				fetchVehicleFiles();
			} else {
				console.error('Failed to save files');
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};
	const confirmDeleteFile = (fileId, filename) => {
		Modal.confirm({
			title: 'Are you sure you want to delete this file?',
			content: `This will permanently delete the file.`,
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			onOk() {
				handleDeleteFile(fileId, filename); // We're now also passing employeeId to handleDeleteFile
			},
			onCancel() {
				// console.log('Cancelled');
			},
		});
	};


	const handleDeleteFile = async (fileId, filename) => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(url + `/manager/deleteVehicleFile/${id}/${fileId}`, {
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (response.ok) {
				message.success(`File ${filename} deleted successfully`);
				fetchVehicleFiles();
			} else {
				message.error('Failed to delete the file');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('An error occurred while deleting the file');
		}
	};

	const handleAddOrUpdate = async () => {
		const token = localStorage.getItem('token');
        try {
            const new_url = url + `/manager/update-vehicle/${details._id}`;
            const method = 'PUT';
            const response = await fetch(new_url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(details)
            });
            const data = await response.json();
            if (response.ok) {
                message.success(`Vehicle updated successfully`);
            }
            else {
            message.error('Failed to update vehicle');
            }
        } catch (error) {
            message.error('Failed to update vehicle');
        }
    };
	
	const handleClick = () => {
		navigate('/manager/vehicle-management');
	};

	return (
		<div>
			<Navbar />
			<div className="vehicle-details">
				<div>
					<div className="d-flex justify-content-between">
						<h2>Vehicle Details</h2>
						<div className="d-flex gap-2">
							<Button onClick={handleClick}>Back</Button>
							<Button onClick={handleAddOrUpdate}>Save</Button>
						</div>
					</div>
					<div>
						<h3>Plate Number: </h3>
						<Input value={details.plateNumber} onChange={(e) => setDetails({ ...details, plateNumber: e.target.value })}/>
						{/* <p>{details.plateNumber}</p> */}
					</div>
					<div>
						<h3>Vehicle Type: </h3>
						<Select style={{ width: '200px' }} placeholder="Select a vehicle type" value={details.vehicleType} onChange={(value) => setDetails({ ...details, vehicleType: value })}>
							<Option value="Car">Car</Option>
							<Option value="Bike">Bike</Option>
						</Select>
					</div>
					<div>
						<h3>Status: </h3>
						<p>{details.vehicleStatus}</p>
					</div>
					<div>
						<h3>Assigned To: </h3>
						<p>{details.assignedEmployee?.employeeName}</p>
					</div>
					<div>
						<h3>Reference Number: </h3>
						<p>{details.assignedEmployee?.refNo}</p>
					</div>
					<div>
						<h3>Company: </h3>
						<p>{details.company?.companyName}</p>
					</div>
					<div>
						<h3>Make: </h3>
						<Input value={details.make} onChange={(e) => setDetails({ ...details, make: e.target.value })}/>
					</div>
					<div>
						<h3>Model: </h3>
						<Input value={details.model} onChange={(e) => setDetails({ ...details, model: e.target.value })}/>
					</div>
					<div>
						<h3>Chassis Number: </h3>
						<Input value={details.chassisNumber} onChange={(e) => setDetails({ ...details, chassisNumber: e.target.value })}/>
					</div>
					<div>
						<h3>Istemara Expiry: </h3>
						<DatePicker value={details.istemaraExpiry} onChange={(date, dateString) => setDetails({ ...details, istemaraExpiry: date })}/>
					</div>
					<br />
					<h2 style={{ textAlign: 'left' }}>Vehicle Files</h2>
					{Array.isArray(vehicleFiles) && vehicleFiles.length > 0 ? (
						<ul>
							{vehicleFiles.map((file) => (
								<li key={file._id}>
									<a
										href={url + `/manager/uploads/${file.filename}`}
										target="_blank"
										rel="noopener noreferrer"
										onClick={(e) => {
											e.preventDefault();
											const token = localStorage.getItem('token');
											fetch(url + `/admin/uploads/${file.filename}`, {
												headers: {
													Authorization: `Bearer ${token}`,
												},
											})
												.then((response) => {
													if (response.ok) {
														return response.blob();
													} else {
														console.error('Failed to fetch file:', response.statusText);
													}
												})
												.then((blob) => {
													const blobUrl = URL.createObjectURL(blob);
													window.open(blobUrl, '_blank');
												})
												.catch((error) => {
													console.error('Error:', error);
												});
										}}
									>
										{file.originalname}
									</a>
									<DeleteOutlined onClick={() => confirmDeleteFile(file._id, file.filename)} style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }} />
								</li>
							))}
						</ul>
					) : (
						<p>No vehicle files found</p>
					)}
					<br />
					<div style={{ display: 'flex', paddingRight: 20, alignItems: 'center' }}>
						<input type="file" multiple onChange={handleFileSelect} />
						<button style={{ border: 'none', padding: '10px 20px', borderRadius: '5px' }} onClick={handleFileUpload}>Upload</button>
					</div>
				</div>
					<div style={{maxHeight:'80vh', overflowY: 'auto', overflowX: 'hidden', paddingTop: 20}}>
						<Timeline mode="alternate">
							{vehicle.map((event, index) => (
								// React code
								<Timeline.Item key={index} color={eventcolor(event.description)}>
									{moment(event.date).format('YYYY-MM-DD')} - {event.description}
								</Timeline.Item>

							))}
						</Timeline>
					</div>
			</div>
		</div>
	);
}

export default VehichleDetails;