import { Card, Col, Modal, Row, Tooltip, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { DeleteOutlined, StopOutlined, ExportOutlined, DownSquareOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const FileCard = ({ title, onView, type }) => {
	const downloadFile = (filename) => {
		if (!filename) {
			message.error(title + ' not found');
			return
		}
		const link = document.createElement('a');
		link.href = `https://hrms-5u7j.onrender.com/admin/uploads/${filename}?action=download`;
		link.download = filename; // This will be respected if the server has the correct headers
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Card hoverable>
			<h5>{title}</h5>
			{
				type !== 6 && onView.length === 0 &&
				<StopOutlined style={{position: 'absolute', top: '10px', right: '10px'}}/>
			}
			<div className='file-buttons'>
				<Tooltip title='View'>
					{type === 6 ?
						<ExportOutlined onClick={onView} style={{ fontSize: 30 }} />
						:
						<div>
							{onView.length > 0 ?
								<a
									href={`https://hrms-5u7j.onrender.com/admin/uploads/${onView[0]?.filename}`}
									target='_blank'
									style={{ color: 'black' }}
								>
									<ExportOutlined style={{ fontSize: 30 }} />
								</a>
								:
								<ExportOutlined style={{ fontSize: 30 }} />
							}
						</div>
					}
				</Tooltip>
				{type !== 6 &&
					<Tooltip title='Download'>
						<DownSquareOutlined onClick={() => downloadFile(onView[0]?.filename)} style={{ fontSize: 30 }} />
					</Tooltip>
				}
			</div>
		</Card>
	)
}

const EmployeeFiles = () => {
	const [personalFiles, setPersonalFiles] = useState({ 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] });
	const [employeeForms, setEmployeeForms] = useState([]);
	const [otherModal, setOtherModal] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const { id } = useParams();
	const employeeId = id;

	useEffect(() => {
		fetchEmployeeFiles();
		fetchEmployeeForms();
	}, [employeeId]);
	const fetchEmployeeFiles = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getEmployeePersonalFiles/${employeeId}`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (!response.ok) {
				message.error('Failed to fetch employee files');
				return
				// throw new Error('Failed to fetch employee files');
			}

			const data = await response.json();
			const options = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] };
			for (const file of data) {
				options[file.type ? file.type : 6].push(file);
			}
			setPersonalFiles(options);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const fetchEmployeeForms = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getEmployeeFormFiles/${employeeId}`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (!response.ok) {
				message.error('Failed to fetch employee forms');
				return
				// throw new Error('Failed to fetch employee forms');
			}

			const data = await response.json();
			setEmployeeForms(data);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleFileSelect = (event) => {
		setSelectedFiles(event.target.files);
	};
	// const handleFileUpload = async () => {
	// 	try {
	// 		if (selectedFiles.length === 0) {
	// 			return;
	// 		}

	// 		const formData = new FormData();
	// 		// formData.append('employeeId', employeeId);

	// 		for (const file of selectedFiles) {
	// 			formData.append('files', file);
	// 		}
	// 		// console.log(employeeId);

	// 		const token = localStorage.getItem('token');
	// 		const response = await fetch(`https://hrms-5u7j.onrender.com/manager/saveEmployeePersonalFiles/${employeeId}`, {
	// 			method: 'POST',
	// 			headers: {
	// 				Authorization: 'Bearer ' + token,
	// 			},
	// 			body: formData,
	// 		});

	// 		if (response.ok) {
	// 			// Successfully saved files, you can perform any necessary actions here
	// 			// console.log('Files uploaded successfully');
	// 			// Refresh the personal files section after successful upload
	// 			fetchEmployeeFiles();
	// 		} else {
	// 			console.error('Failed to save files');
	// 		}
	// 	} catch (error) {
	// 		console.error('Error:', error);
	// 	}
	// };


	return (
		<div className='p-5' style={{ margin: '0 auto' }}>
			<div style={{ display: 'flex', height: '50vh', width: '60vw' }}>
				{/* Left Partition - Personal Files */}
				<div className='personal-files-container'>
					<h3 style={{ padding: '0', paddingBottom: '20px' }}>Personal Files</h3>

					<Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
						<Col span={12}>
							<FileCard title='Updated QID' type={1} onView={personalFiles[1]} />
						</Col>
						<Col span={12}>
							<FileCard title='Updated Passport' type={2} onView={personalFiles[2]} />
						</Col>
						<Col span={12}>
							<FileCard title='Updated License' type={3} onView={personalFiles[3]} />
						</Col>
						<Col span={12}>
							<FileCard title='Updated Health Card' type={4} onView={personalFiles[4]} />
						</Col>
						<Col span={12}>
							<FileCard title='Updated FHC' type={5} onView={personalFiles[5]} />
						</Col>
						<Col span={12}>
							<FileCard title='Others' type={6} onView={() => setOtherModal(true)} />
						</Col>
					</Row>

					<Modal open={otherModal} footer={null} onCancel={() => setOtherModal(false)}>
						{personalFiles[6].length > 0 ? (
							<ul style={{ height: '60%', overflow: 'auto' }}>
								{personalFiles[6].map((file) => (
									<li key={file._id}>
										<a
											href={`https://hrms-5u7j.onrender.com/admin/uploads/${file.filename}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{file.originalname}
										</a>
									</li>
								))}
							</ul>
						) : (
							<p>No other personal files found</p>
						)}
					</Modal>

					{/* <div style={{bottom:0, position:'absolute', display: 'flex', paddingRight: 20, alignItems: 'center', width: '100%'}}>
						
						<button style={{border: 'none', padding: '10px 20px', borderRadius: '5px'}} onClick={handleFileUpload}>Upload</button>
					</div> */}

				</div>

				{/* Right Partition - Employee Forms */}
				<div style={{ flex: 1, padding: '10px' }}>
					<h3 style={{ padding: '0', paddingBottom: '20px' }}>Employee Forms</h3>
					{Array.isArray(employeeForms) && employeeForms.length > 0 ? (
						<ul style={{ height: '60%', overflow: 'auto' }}>
							{employeeForms.map((form) => (
								<li key={form._id}>
									<a
										href={`https://hrms-5u7j.onrender.com/manager/uploads/${form.filename}`}
										target="_blank"
										rel="noopener noreferrer"
										onClick={(e) => {
											e.preventDefault();
											const token = localStorage.getItem('token');
											fetch(`https://hrms-5u7j.onrender.com/manager/uploads/${form.filename}`, {
												headers: {
													Authorization: `Bearer ${token}`,
												},
											})
												.then((response) => {
													if (response.ok) {
														return response.blob();
													} else {
														console.error('Failed to fetch file:', response.statusText);
													}
												})
												.then((blob) => {
													const blobUrl = URL.createObjectURL(blob);
													window.open(blobUrl, '_blank');
												})
												.catch((error) => {
													console.error('Error:', error);
												});
										}}
									>
										{form.originalname}
									</a>

								</li>
							))}
						</ul>
					) : (
						<p>No employee forms found</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default EmployeeFiles;