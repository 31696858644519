import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { message, Button, DatePicker, Select, Radio, Tooltip, Input, FloatButton, Spin } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { SaveOutlined, DownloadOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import moment from "moment";
import html2pdf from "html2pdf.js";
import dayjs from "dayjs";
import AdminNavbar from "../components/AdminNavbar";
import html2canvas from "html2canvas";

const { TextArea } = Input;

const TerminationApproval = () => {

    const { id } = useParams();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        leaveType: '',
        surname: '',
        firstName: '',
        branchDepartment: '',
        employeePayrollNo: '',
        jobTitle: '',
        reasonForLeave: '',
        firstDayOfLeave: null,
        lastDayOfLeave: null,
        dateReturnedToWork: null,
        numberOfLeaveDaysTaken: '',
        leaveEntitlement: '',
        dateJoined: null,
        dateLastLeaveTaken: null,
        totalDaysRemainingAfterPreviousLeave: '',
        currentYearLeaveEntitlement: '',
        additionalLeaveDaysRequested: '',
        ticket: '',
        contactDetailsOnHoliday: '',
        otherRequests: '',
        passportNo: '',
        nationality: '',
        passportExpiryDate: null,
        residenceVisaExpiry: null,
        travelDateAndTime: null,
        returnDateAndTime: null,
        flightNo: '',
        departureAirport: '',
        destinationAirport: '',
        employeeSignature: '',
        employeeSignatureDate: null,
        managerName: '',
        managerSignature: '',
        managerDept: '',
        managerSignatureDate: null,
        department: '',
        chairmanSignature: '',
        chairmanSignatureDate: null,
        eCode: '', // Add the employeeCode field
    });
    const [companyId, setCompanyId] = useState(null);
    const [logoComponent, setLogoComponent] = useState(null);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [adminEmail, setAdminEmail] = useState(null);
    const [manager, setManager] = useState('');

    const fetchFormData = async () => {
        // Immediately set isLoading to true when fetch starts
        setIsLoading(true);

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://hrms-5u7j.onrender.com/admin/get-approval-form/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            console.log('data: ', data)
            if (response.ok) {
                const form = data['formData']
                const man = data['formManager']
                setManager('Approved by ' + man.split('@')[0])
                const parsedData = {
                    ...form,
                    date: form.date ? dayjs(form.date) : null,
                };
                console.log('parsedData: ', parsedData)
                setFormData(parsedData);
                // setFormData(data);
            } else {
                console.error('Error:', data.message || 'Failed to fetch data');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            // Set isLoading to false when fetch is complete
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id)
            fetchFormData()
    }, [id]);

    const handleChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleDownload = async () => {
        setIsButtonVisible(false); // Hide the button
        const pdfElement = document.getElementById('section-to-print');

        if (!pdfElement) {
            console.error('PDF element not found.');
            return;
        }

        const options = {
            margin: 10,
            filename: 'employee-termination.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(pdfElement).set(options).save();
    };

    const calculateDaysOfLeave = () => {
        if (formData.firstDayOfLeave && formData.lastDayOfLeave) {
            const firstDay = dayjs(formData.firstDayOfLeave, 'YYYY-MM-DD');
            const lastDay = dayjs(formData.lastDayOfLeave, 'YYYY-MM-DD');
            const daysOfLeave = lastDay.diff(firstDay, 'days') + 1; // +1 to include the first day in the count
            return daysOfLeave;
        }
        return '';
    };

    const fetchEmployeeInfo = async (employeeCode) => {
        try {

            const token = localStorage.getItem('token');
            const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getByECode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify({ eCodes: employeeCode }),
            });

            if (!response.ok) {
                message.error('Failed to fetch employee information');
                return
                // throw new Error('Failed to fetch employee information');
            }

            const employeeInfo = await response.json(); // This line extracts the body of the response
            console.log(employeeInfo)
            return employeeInfo;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const storedAdminEmail = localStorage.getItem('adminEmail');
        if (storedAdminEmail) {

            const extractedName = storedAdminEmail.split('@')[0];
            const capitalizedName = extractedName.charAt(0).toUpperCase() + extractedName.slice(1);
            setAdminEmail(capitalizedName);
            // // console.log('adminEmail',adminEmail);
        }
    }, []);

    const saveFile = async () => {
        const options = {
            margin: 10,
            filename: 'employee-termination.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        const pdfElement = document.getElementById('section-to-print');

        if (!pdfElement) {
            console.error('PDF element not found.');
            return;
        }

        try {
            const employeeInfo = await fetchEmployeeInfo(formData.eCode);
            const employeeId = employeeInfo[0]._id;
            const token = localStorage.getItem('token');

            html2pdf().from(pdfElement).set(options).outputPdf('blob').then(async (pdfBlob) => {
                const uploadData = new FormData(); // changed the name to uploadData to avoid the conflict
                uploadData.append('file', pdfBlob, 'employee-termination.pdf');
                uploadData.append('employeeId', employeeId); // Add employeeId to uploadData

                const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFileData', {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    body: uploadData, // use uploadData here
                });

                const data = await response.json();
                console.log(data);
            });

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleSave = async () => {
        setIsLoading(true);
        setIsButtonVisible(false); // Hide the button

        const action = "Termination";

        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFile', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eCode: formData.eCode,
                    adminEmail: adminEmail,
                    formId: id,
                    action: action,
                    terminationDate: formData.date
                }),
            });

            if (response.ok) {
                message.success('File saved successfully');
                saveFile()
                navigate('/admin/approvals');
            } else {
                console.error('Failed to save the file');
                message.error('Error saving file');
            }
        } catch (error) {
            console.error('Error in handleSave:', error);
            message.error('Failed to save the file');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async () => {
        if (isDeleteLoading) {
            return;
        }
        setIsDeleteLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://hrms-5u7j.onrender.com/admin/deleteApprovalForm', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify({ formId: id }),
            });
            if (response.ok) {
                message.success('Form deleted successfully');
                navigate('/admin/approvals');
            } else {
                console.error('Failed to delete the form');
                message.error('Error deleting form');
            }
        }

        catch (error) {
            console.error('Error in handleDelete:', error);
            message.error('Failed to delete the form');
        } finally {
            setIsDeleteLoading(false);
        }
    }

    // const handleSave = async () => {
    // 	if (isLoading)
    // 		return;
    // 	setIsLoading(true);
    // 	try {
    //         const employeeInfo = await fetchEmployeeInfo(formData.eCode);
    //         console.log(employeeInfo);

    // 		if (!employeeInfo || employeeInfo.length === 0) {
    //             setIsLoading(false);
    // 			message.error('Employee information not found');
    // 			return;
    // 		}

    // 		const employeeId = employeeInfo[0]._id;  // Assuming the response contains _id as the employee id
    // 		const status = employeeInfo[0].status;
    // 		const action = "Termination";

    // 		const canvas = await html2canvas(document.getElementById('section-to-print'));
    // 		const imgData = canvas.toDataURL('image/png');

    // 		// Convert base64 image data to blob
    // 		const byteCharacters = atob(imgData.split(',')[1]);
    // 		const byteNumbers = new Array(byteCharacters.length);
    // 		for (let i = 0; i < byteCharacters.length; i++) {
    // 			byteNumbers[i] = byteCharacters.charCodeAt(i);
    // 		}
    // 		const byteArray = new Uint8Array(byteNumbers);
    // 		const blob = new Blob([byteArray], { type: 'image/png' });
    //         const url = URL.createObjectURL(blob);
    // 		window.open(url, '_blank');
    // 		URL.revokeObjectURL(url);
    //         setIsLoading(false);
    //         return

    // 		// Create form data and append the necessary values
    // 		const uploadData = new FormData(); // changed the name to uploadData to avoid the conflict
    // 		uploadData.append('file', blob, 'employee-termination.png');
    // 		uploadData.append('companyId', employeeInfo[0].company);
    // 		uploadData.append('employeeId', employeeId); // Add employeeId to uploadData
    // 		uploadData.append('adminEmail', adminEmail);
    //         uploadData.append('formId', id);
    // 		uploadData.append('status', status);
    // 		uploadData.append('action', action);
    //         uploadData.append('terminationDate', formData.date);

    // 		const token = localStorage.getItem('token');
    // 		const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFile', {
    // 			method: 'POST',
    // 			headers: {
    // 				Authorization: 'Bearer ' + token,
    // 			},
    // 			body: uploadData, // use uploadData here
    // 		});

    // 		if (response.ok) {
    // 			//console.log('File saved successfully');
    // 			message.success("File saved successfully");
    //             navigate("/admin/approvals");
    // 		} else {
    // 			console.error('Failed to save the file');
    // 			message.error("Error saving file");
    // 		}
    // 		setIsLoading(false);
    // 	} catch (error) {
    // 		console.error('Error in handleSave:', error);
    // 		message.error("Failed to save the file");
    // 		setIsLoading(false);
    // 	}
    // };

    return (
        <>
            <div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
                <AdminNavbar />
            </div>
            <div className="center-container" style={{ paddingTop: 120 }}>
                {/* <p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p> */}
                <div className="disciplinary-action-form">
                    <div className='local-leave-container'>
                        <div className='form-container termination-letter' id='section-to-print' style={{ border: '1px solid black', padding: '20px', height: '270mm', width: '190mm' }} >
                            <div className='row'>
                                <div className='col-md-2'>{logoComponent}</div>
                                <div className='col-md-8'>
                                    <h2 style={{ fontWeight: 'bolder', border: 'none' }}>Employment Termination Letter</h2>
                                </div>
                                <div className='col-md-2' >
                                </div>
                            </div><br />
                            <div className="row form-fields">
                                <div className="col-md-12 ">
                                    <label>Ref.</label>
                                    <TextArea autoSize value={formData.refNo} style={{ width: '40%', textAlign: 'center' }} type="text" />
                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '15px' }}>
                                    <label>Date:</label>
                                    <DatePicker value={formData.date} disabled style={{ width: '40%' }} />
                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '50px' }}>
                                    <label>Name:</label>
                                    <TextArea autoSize style={{ borderRadius: 0, width: '40%' }} value={formData.employeeName} type="text" />
                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '15px' }}>
                                    <label>Employee Code:</label>
                                    <Input value={formData.eCode} style={{ borderRadius: 0, width: '40%', textAlign: 'center' }} type="text" />
                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '15px' }}>
                                    <label>Designation:</label>
                                    <TextArea autoSize style={{ borderRadius: 0, width: '40%' }} value={formData.position} type="text" />
                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '15px' }}>
                                    <label>QID Number:</label>
                                    <TextArea value={formData.qidNumber} autoSize style={{ borderRadius: 0, width: '40%' }} type="text" />
                                </div>
                            </div>
                            <div className="row form-fields" style={{ paddingTop: '80px' }}>
                                <div className="col-md-12  ">
                                    <label style={{ fontWeight: '500' }}>Dear </label>
                                    <TextArea value={formData.firstName} autoSize style={{ borderRadius: 0, width: '40%' }} type="text" />
                                    <label style={{ fontWeight: '500' }}>,</label>

                                </div>
                                <div className="col-md-12  " style={{ paddingTop: '30px' }}>
                                    <TextArea autoSize style={{ fontWeight: '600', width: '100%', border: 'none', textAlign: 'left', fontSize: 16 }} value={formData.text} ></TextArea>
                                </div>
                            </div>
                            <br /><br />
                            <TextArea value={manager} autoSize style={{ borderRadius: 0, fontStyle: 'italic', width: '40%' }} type="text" />
                            <h6 style={{ fontWeight: 'bolder', marginTop: 20 }}>Manager/Director</h6>
                            <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
                                <label style={{ fontWeight: '600' }}>Distribution:    Personnel File</label>
                                <label style={{ fontWeight: '600' }}>Rev. 00</label>
                            </div>

                        </div>
                    </div>

                    <FloatButton
                        icon={isLoading ? <Spin /> : <SaveOutlined />}
                        tooltip="Save"
                        onClick={handleSave}
                        style={{
                            right: 200,
                        }}
                    />
                    <Tooltip title="Delete" placement='top'>
                        <FloatButton
                            icon={isDeleteLoading ? <Spin /> : <DeleteOutlined />}
                            onClick={handleDelete}
                            style={{
                                right: 150,
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Download" placement='right'>
                        <FloatButton
                            icon={<DownloadOutlined />}
                            onClick={handleDownload}
                            style={{
                                right: 100,
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
        </>
    );
}

export default TerminationApproval;